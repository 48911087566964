import { useEffect, useState } from 'react';

import type { RankingType } from '../types';
import { loadRanksByPositions } from '../utils/api';

export const useTop3Players = () => {
  const [top3Forwards, setTop3Forwards] = useState<RankingType[]>(null);
  const [top3Defenders, setTop3Defenders] = useState<RankingType[]>(null);
  const [top3Midfielders, setTop3Midfielders] = useState<RankingType[]>(null);
  const [top3Goalkeepers, setTop3Goalkeepers] = useState<RankingType[]>(null);

  useEffect(() => {
    loadRanksByPositions('FORWARD').then((data) => {
      setTop3Forwards(data);
    });
    loadRanksByPositions('DEFENDER').then((data) => {
      setTop3Defenders(data);
    });
    loadRanksByPositions('MIDFIELDER').then((data) => {
      setTop3Midfielders(data);
    });
    loadRanksByPositions('GOALKEEPER').then((data) => {
      setTop3Goalkeepers(data);
    });
  }, []);

  return {
    top3Forwards,
    top3Defenders,
    top3Midfielders,
    top3Goalkeepers,
  };
};
