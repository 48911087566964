import clsx from 'clsx';
import { memo, useRef } from 'react';

import { useFitty } from '../../hooks/useFitty';
import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';

import * as style from './style.module.scss';

const { clubCompetition } = getOptions();

const PlayerRank = ({ data }) => {
  const ref = useRef();
  useFitty(ref, { multiLine: false, maxSize: 20, minSize: 8 });
  const upOrDown = data.rankChange < 0 ? 'down' : 'up';
  const changeKind = data.rankChange === 0 ? 'equal' : upOrDown;
  return (
    <div className={clsx(style.playerRank, 'pk-theme--dark')}>
      <p
        ref={ref}
        data-testid='player-rank__rank-number'
        className={clsx(style.playerRankRankNumber, 'player-rank__rank-number')}
      >
        {data.overallRank}
      </p>
      {!clubCompetition && (
        <p
          className={clsx(
            style.playerRankRankChange,
            style[makeCamelCase(`kind-${changeKind}`)],
            'player-rank__rank-change',
          )}
        ></p>
      )}
    </div>
  );
};

export { PlayerRank as IsolatedPlayerRank };
export default memo(PlayerRank);
