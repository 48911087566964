import type { FC, ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { actions } from '../../models';
import { loadRanksViewAction } from '../../models/rankings-view';
import { useDispatch } from '../../store';
import type { Item } from '../../types';

import { ItemDisplayerPure } from './ItemDisplayerPure';

export const ItemDisplayerView: FC<{ items: Item[]; lastItem?: ReactNode }> = ({ items, lastItem }) => {
  const dispatch = useDispatch();
  const setViewOffset = useCallback(() => dispatch(actions.rankingsView.setOffset()), [dispatch]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (!lastItem && inView) {
      dispatch(setViewOffset());
      dispatch(loadRanksViewAction());
    }
  }, [inView, dispatch, setViewOffset, lastItem]);

  return (
    <>
      <ItemDisplayerPure items={items} lastItem={lastItem} />
      <div ref={ref}></div>
    </>
  );
};
