import { useEffect } from 'react';

const withGlobalSelectorScope = (options) => {
  const defaultOptions = {
    globalSelector: '',
    locationClassNamePrefix: 'page-',
  };

  const opts = {
    ...defaultOptions,
    ...options,
  };

  return (WrappedComponent) => {
    const WithGlobalSelectorScope = (props) => {
      useEffect(() => {
        const cName = opts.locationClassNamePrefix + WrappedComponent.name;

        const globalContainer = document.querySelector(opts.globalSelector);
        globalContainer && globalContainer.classList.add(cName);

        return () => {
          globalContainer && globalContainer.classList.remove(cName);
        };
      }, []);

      return <WrappedComponent {...props} />;
    };
    return WithGlobalSelectorScope;
  };
};

export default withGlobalSelectorScope;
