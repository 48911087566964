import type { FittyInstance } from 'fitty';
import fitty from 'fitty';
import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

export type FittyOptions = {
  minSize?: number;
  maxSize?: number;
  multiLine?: boolean;
};

export const useFitty = (targetRef: MutableRefObject<HTMLElement>, options?: FittyOptions) => {
  const fittyInstance = useRef<FittyInstance>();

  useEffect(() => {
    if (!targetRef.current) return;

    const maxSize = parseInt(getComputedStyle(targetRef.current).fontSize);
    fittyInstance.current = fitty(targetRef.current, {
      maxSize,
      minSize: maxSize / 2,
      ...options,
    });
    fittyInstance.current.fit();

    return () => {
      fittyInstance.current && fittyInstance.current.unsubscribe();
    };
  }, [options, targetRef]);
};
