import {
  defaultParams,
  getActiveTeams,
  getActivePositions,
  getFilteredTeam,
  getPlayerCountryCode,
  getTeamCountryCode,
} from '../misc';

import { formatUrl } from './fetch';

export type additionalParamsType = {
  limit?: number;
  teamCountryCode?: string;
  playerCountryCode?: string;
  teamId?: string;
  fieldPosition?: string;
};

export const createRankingsParams = (state?: any, additionalParams?: additionalParamsType) => {
  let params = {
    ...defaultParams,
    ...additionalParams,
  };

  if (state) {
    const teams = getActiveTeams(state.teams);
    const positions = getActivePositions(state.filter);
    const teamId = getFilteredTeam(state.route);
    const playerCountryCode = getPlayerCountryCode(state.route);
    const teamCountryCode = getTeamCountryCode(state.route);
    params = {
      ...params,
      fieldPosition: positions.map((pos) => pos.id.toUpperCase()),
      teamId: teamId || teams.map((ct) => ct.id),
      playerCountryCode: playerCountryCode,
      teamCountryCode: teamCountryCode,
      limit: state.rankings?.limit,
      offset: state.rankings?.offset,
    };
  }

  return params;
};

export const createRankingsURL = (url: string, state?: any, additionalParams?: additionalParamsType) => {
  const params = createRankingsParams(state, additionalParams);
  return formatUrl(url, params);
};
