import { PkIcon } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { actions } from '../../models';
import { useSelector, useDispatch } from '../../store';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';

import * as style from './style.module.scss';

export const TeamsDropdown = () => {
  const { competitionCode } = getOptions();
  const teams = useSelector((state) => state.teams.list);
  const selectedTeam = useSelector((state) => state.teams.selected);
  const dispatch = useDispatch();
  const setSelectedTeam = useCallback((p) => dispatch(actions.teams.setSelectedTeam(p)), [dispatch]);

  const { i18n } = useTranslation();
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const teamToSelect = teams.find((item) => item.id === e.target.value);
    setSelectedTeam && setSelectedTeam(teamToSelect);
    GoogleTagManagerHelper.event(
      'Click',
      'Change top 3 team',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Change top 3 team' },
      'performance_zone',
    );
    GoogleTagManagerHelper.event(
      'Click',
      `Select top 3 team - ${e.target.value}`,
      'Performance Zone',
      { section_reference: 'fpz', interaction: `Select top 3 team - ${e.target.value}` },
      'performance_zone',
    );
  };

  const language = i18n?.language;
  const team = teams.find((item) => item.id === selectedTeam?.id);
  return (
    <div className={style.teamsDropdown}>
      <button
        className={clsx(
          style.teamsDropdownSelector,
          style[makeCamelCase(`teams-dropdown__selector--${competitionCode}`)],
        )}
      >
        {team && team.translations.displayName[language.toUpperCase()]}
        <PkIcon
          className={clsx(style.pkIcon, style[makeCamelCase(`pk-icon--${competitionCode}`)])}
          name='navigation-chevron-down-XS'
        />
      </button>
      <div className={clsx(style.teamsDropdownSelect, 'pk-theme--light')}>
        <select value={selectedTeam?.id} onChange={onChange}>
          {teams.map((ct) => (
            <option key={ct.id} value={ct.id}>
              {ct.translations.displayName[language.toUpperCase()]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TeamsDropdown;
