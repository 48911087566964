import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RankingType } from '../types';
import { endpoints } from '../utils/api/endpoints';
import { cachedFetch, formatUrl } from '../utils/api/fetch';
import { createRankingsParams } from '../utils/api/utils';

export const loadRanksAction: AsyncThunk<RankingType[], void, unknown> = createAsyncThunk(
  'ranks/load',
  async (_, { getState }) => {
    const state = getState();
    const params = createRankingsParams(state);

    const url = formatUrl(endpoints.rankings, params);
    const response = (await cachedFetch<RankingType[]>(url)) ?? [];

    return response?.filter((value) => value.rank.totalPoints > 0) ?? [];
  },
);

export interface RankingsStateType {
  isLoading: boolean;
  globalRanks: RankingType[];
  offset: number;
  limit: number;
}

const initialState = {
  isLoading: false,
  globalRanks: [],
  offset: 0,
  limit: 20,
};

export const rankings = createSlice({
  name: 'ranks',
  initialState: initialState,
  reducers: {
    setOffset: (state) => {
      if (state.globalRanks.length >= state.offset + state.limit) {
        state.offset += state.limit;
      }
    },
    resetOffset: (state) => {
      state.offset = 0;
      state.globalRanks = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRanksAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadRanksAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (!payload) payload = [];
      if (!state.globalRanks) state.globalRanks = [];
      //I filter out the ranks that are already in the lasts element of the state to avoid duplicates
      const lastIds = state.globalRanks?.slice(-state.limit).map((ct) => ct.player.id);
      payload.filter((ct) => !lastIds?.includes(ct.player.id)).forEach((ct) => state.globalRanks.push(ct));
    });
  },
});
