import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Player, Rank, ReasonOfRank } from '../../types';
import { getTranslatedProp } from '../../utils/l10n';
import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';
import PlayerBadges from '../PlayerBadges';

import * as style from './style.module.scss';

const PlayerData = ({
  player,
  points,
  reason,
  badges,
}: {
  player: Player;
  points: Rank['totalPoints'];
  reason: ReasonOfRank;
  badges?: Rank['badges'];
}) => {
  const { t } = useTranslation();

  const isUNL = getOptions().competitionCode?.toLowerCase() === 'unl';
  const { competitionCode } = getOptions();

  const reasonPhrase = useMemo(() => getTranslatedProp(reason?.phrase, 'translations'), [reason]);
  const reasonLabel = useMemo(
    () => reason?.statistics?.map((s) => `${s.value} ${getTranslatedProp(s, 'translations')}`).join(' • '),
    [reason],
  );

  return (
    <div className={style.playerData}>
      {badges?.length > 0 && <PlayerBadges badges={badges}></PlayerBadges>}
      <div className={clsx(style.playerDataName, style[makeCamelCase(`player-data__name--${competitionCode}`)])}>
        {getTranslatedProp(player, 'names')}
      </div>
      <div className={style.playerDataPosition}>
        {!!player.team && (
          <div className={style.playerDataPositionFlag}>
            {isUNL ? (
              <span
                className={clsx(
                  style.teamFlag,
                  style.flagUnl,
                  style[makeCamelCase(`flag-${player.team.countryCode}`)],
                  'lazyloaded',
                )}
                title={getTranslatedProp(player.team.names)}
              ></span>
            ) : (
              <img loading='lazy' alt={getTranslatedProp(player.team?.teamCodes)} src={player.team?.logos.SMALL} />
            )}
          </div>
        )}
        <div
          className={clsx(
            style.playerDataPositionRole,
            style[makeCamelCase(`player-data__position-role--${competitionCode}`)],
          )}
        >
          <p>{getTranslatedProp(player, 'fieldPositionDisplayName')}</p>
        </div>
        <div
          data-testid='player-data__position-points'
          className={clsx(style.playerDataPositionPoints, 'player-data__position-points')}
        >
          <p>
            <span>{Math.round(points)}</span> {t('pts')}
          </p>
        </div>
      </div>
      {!!reasonLabel && (
        <div className={style.playerDataCaption}>
          <span>{reasonPhrase}: </span> {reasonLabel}
        </div>
      )}
    </div>
  );
};

export default memo(PlayerData);
