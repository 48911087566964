declare global {
  interface Window {
    apiKey: string;
  }
}

export const headers = new Headers({
  'X-Api-Key': window.apiKey,
});

export const formatUrl = (url: string, filters: Record<string, any>) => {
  const Url = new URL(url);
  for (const key in filters) {
    const value = filters[key];
    if ((value ?? '') === '' || (Array.isArray(value) && !value.length)) {
      Url.searchParams.delete(key);
    } else {
      Url.searchParams.set(key, `${value}`);
    }
  }
  return Url.toString();
};

const calls = new Map<string, WeakRef<Promise<any>>>();
export const cachedFetch = <T = any>(url: string): Promise<T> => {
  if (calls.has(url)) {
    return calls.get(url)?.deref();
  }

  const promise = fetch(url, { headers })
    .then((resp) => {
      if (!resp.ok) {
        console.error(`Error calling url: ${url}`, resp);
        return undefined;
      }

      return resp.json();
    })
    .catch((error) => {
      console.warn(`Error calling url: ${url}`, error);
      return undefined;
    })
    .finally(() => {
      calls.delete(url);
    });

  calls.set(url, new WeakRef(promise));
  return promise;
};

const fspLimit = window.fspApiLimit ?? 50;
export async function resursiveCall<T = any>(url: string, limit = fspLimit, offset = 0): Promise<T[]> {
  let result: T[] = [];
  let tempResult: T[] = [];
  let iteration = 0;

  limit ??= fspLimit;
  let tempLimit = limit > fspLimit ? fspLimit : limit;

  do {
    const tempUrl = formatUrl(url, {
      limit: tempLimit,
      offset: (offset ?? 0) + iteration * fspLimit,
    });
    tempResult = (await cachedFetch<T[]>(tempUrl)) ?? [];

    iteration++;
    result = [...result, ...tempResult];
    if (tempResult.length < fspLimit || result.length >= limit) break;

    tempLimit = limit - result.length > fspLimit ? fspLimit : limit - result.length;
  } while (tempResult.length || iteration < 20);

  if (result.length > limit) {
    result.length = limit;
  }

  return result;
}
