import clsx from 'clsx';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import GptBanner from '../../components/GptBanner';
import { ItemDisplayerView } from '../../components/ItemDisplayer/ItemDisplayerView';
import Modal from '../../components/Modal';
import UpperBar from '../../components/UpperBar';
import { useLeague } from '../../hooks/useLeague';
import { useReset } from '../../hooks/useReset';
import { useRouteState } from '../../hooks/useRouteState';
import { actions } from '../../models';
import { loadRanksViewAction } from '../../models/rankings-view';
import { useDispatch, useSelector } from '../../store';
import { loadCountry, loadTeam } from '../../utils/api';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getOptions } from '../../utils/optionsManager';
import withGlobalSelectorScope from '../../utils/withGlobalSelectorScope';

import * as style from './style.module.scss';

const { clubCompetition } = getOptions();

export type rankType = 'team' | 'country' | 'league' | 'position';

const Ranks = ({ type }: { type: rankType }) => {
  const [country, setCountry] = useState<any>();
  const globalRanks = useSelector((state) => state.rankingsView.items);

  const selectedTeam = useSelector((state) => state.teams.selected);
  const teamsList = useSelector((state) => state.teams.list);
  const [team, setTeam] = useState(selectedTeam);
  const dispatch = useDispatch();
  const togglePosition = useCallback((pos) => dispatch(actions.filter.togglePosition(pos)), [dispatch]);
  const { setTeamId, setPlayerCountryCode, setTeamCountryCode, setPosition } = useRouteState();
  const { resetRoute, resetViewOffset } = useReset();

  const { teamId, countryCode, fieldPosition } = useParams();

  const trackData = (path: string, subtype: string, cs3: string, type: string = null) => {
    path = path.replace(/- | /g, '').toLowerCase();
    GoogleTagManagerHelper._push({
      event: 'Page Meta',
      pageName: `/${getOptions().competitionUrl.replace('-', '')}/performancezone/${path}/`,
      pageType: type,
      pageSubType: subtype,
      contentSection3: cs3,
    });
    GoogleTagManagerHelper._push({
      event: 'virtPath',
      virtPath: `/${getOptions().competitionUrl.replace('-', '')}/performancezone/${path}/`,
    });
  };
  const { league, isLoaded: leagueLoaded } = useLeague(countryCode, type);
  useEffect(() => {
    league &&
      leagueLoaded &&
      trackData(league?.translations?.name['EN'], 'FPZ national league ranking', 'national league', 'ranking');
  }, [league, leagueLoaded]);

  useEffect(() => {
    if (type === 'position') {
      setPosition(fieldPosition.toLowerCase());
      trackData(fieldPosition.toLowerCase(), 'FPZ player grid', 'team', 'ranking');
    } else if (type === 'team' && clubCompetition) {
      //I have a number team ID because I'm in the club team
      setTeamId(teamId);
    } else if (type === 'team' || type === 'league') {
      //I am in a NTC team or in a league and I have a country code
      setTeamCountryCode(countryCode ?? teamId);
    } else {
      //I filter for player country code
      setPlayerCountryCode(countryCode);
    }

    return () => {
      resetRoute();
    };
  }, [
    type,
    togglePosition,
    fieldPosition,
    teamId,
    setTeamId,
    setPlayerCountryCode,
    countryCode,
    setTeamCountryCode,
    resetRoute,
    setPosition,
  ]);

  const id = teamId ?? fieldPosition ?? countryCode;

  useEffect(() => {
    resetViewOffset();
    dispatch(loadRanksViewAction());
  }, [dispatch, resetViewOffset]);

  useEffect(() => {
    let mounted = true;

    if (type === 'country') {
      loadCountry(id).then((c) => {
        trackData(c?.translations?.countryName['EN'], 'FPZ country ranking', 'country', 'ranking');
        mounted && setCountry(c);
      });
    }

    if (type === 'team') {
      if (clubCompetition) {
        loadTeam(id).then((c) => {
          trackData(c?.id, 'FPZ player grid', 'team', 'ranking');
          mounted && setTeam(c);
        });
      } else {
        const teamFiltered = teamsList.filter((tm) => tm.countryCode === id)?.[0];
        if (teamFiltered) {
          trackData(teamFiltered?.id, 'FPZ player grid', 'team', 'ranking');
          mounted && setTeam(teamFiltered);
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [id, type, teamsList]);
  const { i18n, t } = useTranslation();
  const language = i18n?.language;

  const topItems = globalRanks.slice(0, 6);
  const otherItems = globalRanks.slice(6);

  const title = useMemo(() => {
    switch (type) {
      case 'country':
        return country?.translations?.countryName[language.toUpperCase()];
      case 'team': {
        return team?.translations?.displayName[language.toUpperCase()];
      }
      case 'league':
        return league?.translations?.name[language.toUpperCase()];
      default:
        break;
    }
  }, [country?.translations?.countryName, language, league?.translations?.name, team?.translations?.displayName, type]);

  const h1PageTitle = useMemo(() => {
    return type !== 'position' ? (
      <>
        {title}:<p>{t('top_players')}</p>
      </>
    ) : (
      <>{t('top_field_position').replace('{fieldPosition}', t(fieldPosition?.toLowerCase()))}</>
    );
  }, [type, title, t, fieldPosition]);

  return (
    <Modal
      showModal={true}
      className={`fpz_modal_content pk-competition--${getOptions().competitionCode} pk-theme--light`}
      overlayClassName={'fpz_modal'}
    >
      <UpperBar showButton={true} />
      <div
        className={clsx(style.fpzTeam, `fpz-team--${type?.toLowerCase()}`, {
          [style.fpzTeamBg]: !getOptions().clubCompetition,
          [style[`${selectedTeam?.countryCode?.toLowerCase()}`]]: !getOptions().clubCompetition && id?.toLowerCase(),
        })}
      >
        <div className='pk-container'>
          <div className='pk-col pk-col--span-12'>
            <div className={clsx(style.fpzTeamTitle)}>
              {type === 'country' && (
                <div className={style.fpzTeamLogo}>
                  <img loading='lazy' src={country?.countryFlagUrl} />
                </div>
              )}
              {type === 'team' && (
                <div className={style.fpzTeamLogo}>
                  <img loading='lazy' src={team?.mediumLogoUrl} />
                </div>
              )}
              <h1>{h1PageTitle}</h1>
            </div>
          </div>
        </div>
        <div className='pk-container'>{!!topItems?.length && <ItemDisplayerView items={topItems} />}</div>
        <div className='pk-container'>
          <GptBanner
            slotType='leaderboard'
            adUnitPath={'/5874/{competitionBanner}.{language}/exclusive_fedex'}
            position='top'
          />
        </div>
        <div className='pk-container'>{!!otherItems?.length && <ItemDisplayerView items={otherItems} />}</div>
      </div>
    </Modal>
  );
};

const globalSelectorScope = withGlobalSelectorScope({
  globalSelector: '.fpz-App',
})(Ranks);

export { Ranks as IsolatedRanks };

export default memo(globalSelectorScope);
