import clsx from 'clsx';
import { Fade } from 'react-awesome-reveal';

import type { Item } from '../../types';
import { getTranslatedProp } from '../../utils/l10n';
import { getContext, getPlayerLink } from '../../utils/misc';
import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';
import PlayerData from '../PlayerData';
import PlayerPicture from '../PlayerPicture';
import PlayerRank from '../PlayerRank';

import * as style from './style.module.scss';

const { competitionCode, theme } = getOptions();
const isLightTheme = theme === 'light';

export const PlayerCard = ({ item, top3 }: { item: Item; top3?: boolean }) => (
  <Fade direction={'up'} duration={500} triggerOnce>
    <a href={getPlayerLink(item.player.id)}>
      <div
        className={clsx(
          'pk-border',
          style.playerCard,
          style.playerCardWithHover,
          `player-card--${competitionCode}`,
          style[makeCamelCase(`player-card--${competitionCode}`)],
          { [style.playerCardTop3]: top3 },
          { ['pk-theme--light']: top3 },
          style[makeCamelCase(`player-card--with-hover${isLightTheme ? '--light' : ''}`)],
        )}
      >
        <div className={style.playerCardPictureWrapper}>
          <PlayerPicture
            playerName={getTranslatedProp(item.player, 'names')}
            pic={getContext(item.player)?.images?.imageUrl}
          />
        </div>
        <div className={clsx(style.playerCardRankWrapper, 'player-card__rank-wrapper')}>
          <PlayerRank data={item.rank} />
        </div>
        <div className={style.playerCardPlayerDataWrapper}>
          <PlayerData
            player={item.player}
            points={item.rank.totalPoints}
            reason={item.rank.reasonOfRank}
            badges={item.rank.badges}
          />
        </div>
      </div>
    </a>
  </Fade>
);

export default PlayerCard;
