import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

import type { Item } from '../../types';
import PlayerCard from '../PlayerCard';

import * as style from './style.module.scss';

export const ItemDisplayerPure: FC<{ items: Item[]; lastItem?: ReactNode }> = ({ items, lastItem }) => {
  return (
    <>
      {items.map((item, i) => (
        <div
          key={item.player.id}
          data-testid={`item-displayer__player-card-wrapper--${i}`}
          className={clsx(
            style.itemDisplayerPlayerCardWrapper,
            'pk-col',
            'pk-col--span-12',
            'pk-col--span-sm-4',
            'pk-col--span-md-6',
            'pk-col--span-lg-4',
          )}
        >
          <PlayerCard item={item} top3={false} />
        </div>
      ))}
      {!!lastItem && (
        <div
          data-testid='item-displayer__player-card-wrapper'
          className={clsx(
            style.itemDisplayerPlayerCardWrapper,
            'pk-col',
            'pk-col--span-12',
            'pk-col--span-sm-4',
            'pk-col--span-md-6',
            'pk-col--span-lg-4',
          )}
        >
          {lastItem}
        </div>
      )}
    </>
  );
};
