import clsx from 'clsx';

import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';

import * as style from './style.module.scss';

const { competitionCode, theme } = getOptions();

export const PlayerPicture = ({ pic, playerName }: { pic: string; playerName?: string }) => {
  const checkIfShowPlaceHolder = ({ target: img }) => {
    if (img.naturalHeight === 1 || img.naturalWidth === 1) {
      img.src = 'https://img.uefa.com/imgml/uefacom/elements/players/fallback_player.jpg';
    }
  };
  return (
    <div
      className={clsx(style.playerPicture, style[makeCamelCase(`player-picture--${theme}`)], 'player-picture', 'h-100')}
    >
      <div
        className={clsx(
          style.playerOverlay,
          style[makeCamelCase(`player-overlay--${competitionCode}`)],
          'player-overlay',
          `player-overlay--${competitionCode}`,
        )}
      ></div>
      <img
        loading='lazy'
        alt={playerName}
        data-testid='player-picture__image'
        className={clsx(style.playerPictureImage, 'player-picture__image', 'h-100')}
        src={pic}
        onLoad={checkIfShowPlaceHolder}
      />
    </div>
  );
};

export { PlayerPicture as IsolatedPlayerPicture };
export default PlayerPicture;
