import { PkButton } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLeague } from '../../hooks/useLeague';
import type { rankType } from '../../pages/Ranks';
import type { RankingType, Team } from '../../types';
import { loadCountry } from '../../utils/api';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';
import PlayerCard from '../PlayerCard';
import PlayerCardMini from '../PlayerCardMini';
import TeamsDropdown from '../TeamsDropdown';

import * as style from './style.module.scss';

const { clubCompetition, competitionCode } = getOptions();

const Top3Content: FC<{
  items: RankingType[];
  countryCode?: string;
  team?: Team;
  type?: rankType;
  useMini?: boolean;
}> = ({ items, countryCode, team, type = 'team', useMini }) => {
  const { i18n, t } = useTranslation();
  const language = i18n?.language;

  const [country, setCountry] = useState<any>();
  const { league } = useLeague(countryCode, type);

  useEffect(() => {
    let mounted = true;

    if (type === 'country') {
      loadCountry(countryCode).then((c) => {
        mounted && setCountry(c);
      });
    }

    return () => {
      mounted = false;
    };
  }, [countryCode, type]);

  const title = useMemo(() => {
    switch (type) {
      case 'country':
        return country?.translations?.countryName[language.toUpperCase()];
      case 'team': {
        return team?.translations?.displayName[language.toUpperCase()];
      }
      case 'league':
        return league?.translations?.name[language.toUpperCase()];
      default:
        break;
    }
  }, [country?.translations?.countryName, language, league?.translations?.name, team?.translations?.displayName, type]);

  const buttonClicked = () => {
    GoogleTagManagerHelper.event(
      'Click',
      'Show more top 3',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Show more top 3' },
      'performance_zone',
    );
  };
  const top3Link = useMemo(() => {
    if (type === 'team' && clubCompetition) return `#/${type}/${team?.id}`;
    return `#/${type}/${countryCode}`;
  }, [countryCode, team, type]);
  if ((type === 'team' && !team) || !items?.length) return null;

  return (
    <div className={style.fpzTop3Container}>
      <div className={clsx(style.fpzTop3Title, style[makeCamelCase(`fpz-top3__title--${competitionCode}`)])}>
        {clubCompetition ? title : <TeamsDropdown />}: {t('top_3_players')}
      </div>
      <div className={clsx(style.fpzTop3CardWrapper, { [style.fpzTop3CardWrapperCol]: !useMini })}>
        {items.map((item, i) => (
          <div
            key={item.player.id}
            data-testid={`fpz-top3__card--${i}`}
            className={clsx({
              [style.fpzTop3Card]: true,
              [style.fpzTop3CardMini]: useMini,
              ['pk-col pk-col--span-12 pk-col--span-md-4']: !useMini,
            })}
          >
            {useMini ? (
              <div className={style.playerCardMini}>
                <PlayerCardMini item={item} />
              </div>
            ) : (
              <PlayerCard item={item} top3={true} />
            )}
          </div>
        ))}
      </div>
      <div className={clsx(style.fpzTop3ButtonWrapper)}>
        <PkButton
          className='pk-button'
          highContrast={!clubCompetition}
          linkHref={top3Link}
          appearance='outlined'
          type='button'
          onClick={buttonClicked}
        >
          {t('top3_button')}
        </PkButton>
      </div>
    </div>
  );
};

export default memo(Top3Content);
