import { PkButton } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useReset } from '../../hooks/useReset';
import { actions } from '../../models';
import { useSelector, useDispatch } from '../../store';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { anyFilter } from '../../utils/misc';

import * as style from './style.module.scss';

const FilterCTA = ({ onDone }: { onDone?: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { resetPositions, resetRoute, resetTeams } = useReset();
  const hide = useCallback(() => dispatch(actions.filter.hideFilter()), [dispatch]);

  const clearEnabled = useSelector((state) => anyFilter(state?.filter, state?.teams));

  const clearClickHandler = () => {
    GoogleTagManagerHelper.event(
      'Click',
      'Clear filter',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Clear filter' },
      'performance_zone',
    );
    resetPositions?.();
    resetRoute?.();
    resetTeams?.();
  };

  const doneClickHandler = () => {
    onDone?.();
    hide?.();
  };

  return (
    <div className={clsx(style.filterCta, 'filter-cta')}>
      <div
        data-testid='filter-cta__btn--clear'
        className={clsx(style.filterCtaBtn, style.filterCtaBtnClear)}
        onClick={clearClickHandler}
      >
        <PkButton className={clsx(style.pkButton, 'pk-button')} appearance='outlined' disabled={!clearEnabled}>
          {t('clear')}
        </PkButton>
      </div>
      <div
        data-testid='filter-cta__btn--done'
        className={clsx(style.filterCtaBtn, style.filterCtaBtnDone)}
        onClick={doneClickHandler}
      >
        <PkButton className={clsx(style.pkButton, 'pk-button')} appearance='contained'>
          {t('done')}
        </PkButton>
      </div>
    </div>
  );
};

export { FilterCTA as IsolatedFilterCTA };
export default memo(FilterCTA);
