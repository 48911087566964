import { env } from '../enviroments';
import { getOptions } from '../optionsManager';

export const endpoints = {
  rankings: `${env.performances}/v1/rankings`,
  team: `${env.competitions}/v2/teams`,
  teams: `${env.competitions}/v2/teams?competitionId=${getOptions().competitionId}&seasonYear=${
    getOptions().seasonYear ?? 'current'
  }&phase=${getOptions().phase}`,
  country: `${env.competitions}/v2/countries?status=CURRENT`,
  league: `${env.domestic}/v2/competitions?age=ADULT&competitionType=LEAGUE&limit=1&offset=0&sex=MALE`,
  sponsor: `${env.uefa}/sponsors`,
  sponsorService: `${env.sponsor}v2/sponsors?competitionAndPhase=${getOptions().competitionId}:${
    getOptions().sponsorPhase || 'TOURNAMENT'
  }&platform=DEFAULT`,
};
