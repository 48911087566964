import { PkIcon } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import * as style from './style.module.scss';

export const PlayerBadges = ({ badges }) => {
  const { t } = useTranslation();
  return (
    badges.includes('FAST_RISER') && (
      <div className={clsx(style.playerBadge, 'player-badge')}>
        {t('fast_riser')}
        <PkIcon name='gaming-wildcard' size='14' />
      </div>
    )
  );
};

export default PlayerBadges;
