import type { Team } from '@uefadigital/panenka';

import type { Player, FilterStateType, TeamsStateType, RouteStateType } from '../types';

import i18n from './i18n';
import { getOptions } from './optionsManager';

const { competitionCode, competitionGroup, competitionId, seasonYear } = getOptions();

export const defaultParams = {
  competitionGroup: (competitionGroup ?? competitionCode)?.toUpperCase(),
  offset: 0,
  seasonYear: seasonYear ?? 'current',
  fieldPosition: null,
  teamId: null,
  teamCountryCode: '',
  playerCountryCode: '',
  limit: 20,
};

export const getActiveTeams = (teams: TeamsStateType) => (teams?.list ? teams?.list.filter((ct) => ct.isFilter) : []);
export const getActivePositions = (data: FilterStateType) =>
  data?.positions ? data.positions.filter((pos) => pos.active) : [];
export const getFilteredTeam = (data: RouteStateType) => (data?.teamId ? data.teamId : 0);
export const getPlayerCountryCode = (data: RouteStateType) => (data?.playerCountryCode ? data.playerCountryCode : '');
export const getTeamCountryCode = (data: RouteStateType) => (data?.teamCountryCode ? data.teamCountryCode : '');
export const anyTeam = (teams: TeamsStateType) => teams?.list && teams.list.some((ct) => ct.isFilter);
export const anyPosition = (data: FilterStateType) => data?.positions && data.positions.some((pos) => pos.active);
export const anyFilter = (filter: FilterStateType, teams: TeamsStateType) => anyTeam(teams) || anyPosition(filter);
export const noFilters = (filter: FilterStateType, teams: TeamsStateType) => !anyFilter(filter, teams);

export const getContext = (item: Player) =>
  item?.context
    ?.filter((c) => c.competition?.id === competitionId)
    ?.find((c) => !seasonYear || seasonYear === 'current' || c.competition?.seasonYear === seasonYear) ??
  item?.context?.[0];

export const getCompetitionURL = () => `//${window.location.host}/${getOptions().competitionUrl}`;

export const mainAppParams: { iv?: boolean } = {};
export const isIV = () => !!mainAppParams.iv;

export const getDisplayName = (item: Team, language: string) => {
  return item?.translations?.displayName[language.toUpperCase()];
};

export const orderTeams = (teams: Team[]) => {
  const language = i18n?.language;
  return teams.sort((a, b) => {
    if (getDisplayName(a, language) < getDisplayName(b, language)) return -1;
    if (getDisplayName(a, language) > getDisplayName(b, language)) return 1;
    return 0;
  });
};

export const getPlayerLink = (id: string) => {
  const baseUrl = getOptions().competitionUrl;

  return `/${baseUrl}/${getOptions().clubCompetition ? 'clubs' : 'teams'}/players/${id}/`;
};
