import type { Team } from '@uefadigital/panenka';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Filter from './components/Filter';
import { actions } from './models';
import { loadTeamsAction } from './models/teams';
import Main from './pages/Main';
import Ranks from './pages/Ranks';
import { useDispatch, useSelector } from './store';
import { loadTeam } from './utils/api';
import EventBus from './utils/EventBus';
import { getOptions } from './utils/optionsManager';

const { competitionCode, theme } = getOptions();

export const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const filterVisible = useSelector((state) => state.filter.isVisible);
  const setPreferred = useCallback((t) => dispatch(actions.teams.setPreferred(t)), [dispatch]);
  const language = i18n?.language;
  const filterClass = filterVisible ? 'fpz-App--filter-open' : '';

  useEffect(() => {
    dispatch(loadTeamsAction());
    if (window.savedFavouriteTeam) {
      loadTeam(window.savedFavouriteTeam.id).then((team) => {
        setPreferred(team);
      });
    }
    setPreferred(window.savedFavouriteTeam);
    EventBus.on('preferredTeamChanged', (team: Team) => {
      if (!team || team?.countryCode) {
        setPreferred(team);
      } else {
        loadTeam(window.savedFavouriteTeam.id).then((t) => {
          setPreferred(t);
        });
      }
    });
    //eslint-disable-next-line
  }, []);

  return (
    <Router>
      <div pk-competition={competitionCode} pk-theme={theme} className={`fpz-App ${filterClass} lang-${language}`}>
        <Routes>
          <Route path='/team/:teamId' element={<Ranks type='team' />} />
          <Route path='/country/:countryCode' element={<Ranks type='country' />} />
          <Route path='/league/:countryCode' element={<Ranks type='league' />} />
          <Route path='/position/:fieldPosition' element={<Ranks type='position' />} />

          <Route path='/' element={<Main />} />
        </Routes>
        <Filter />
      </div>
    </Router>
  );
};

export default memo(App);
