const filterKey = 'fpz-filter';

export const storePositions = (state) => {
  const current = JSON.parse(localStorage.getItem(filterKey) ?? '{}');
  const item = {
    ...current,
    positions: state.positions.filter((pos) => pos.active),
  };
  localStorage.setItem(filterKey, JSON.stringify(item));
};

export const storeTeams = (state) => {
  const current = JSON.parse(localStorage.getItem(filterKey) ?? '{}');
  const item = {
    ...current,
    teams: state.list.filter((pos) => pos.isFilter),
  };
  localStorage.setItem(filterKey, JSON.stringify(item));
};
