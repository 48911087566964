import type { ComponentType, ReactNode } from 'react';
import { Component } from 'react';

import { env } from './enviroments';

export interface ErrorBoundaryProps {
  children: ReactNode;
  onComponentError?: (error: Error, info: any) => unknown;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
  }
  state = { hasError: false };

  componentDidCatch(error: Error, info: any) {
    const { onComponentError } = this.props;

    if (onComponentError && typeof onComponentError === 'function') onComponentError(error, info);

    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service

    env !== 'prod' && console.warn(error, info);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) return <></>;

    return this.props.children;
  }
}

export function withErrorBoundary<T>(BaseComponent: ComponentType<T>): ComponentType<T> {
  return function Wrapped(props: T & Pick<ErrorBoundaryProps, 'onComponentError'>) {
    return (
      <ErrorBoundary onComponentError={props.onComponentError}>
        <BaseComponent {...props} />
      </ErrorBoundary>
    );
  };
}
