import { defineCustomElements } from '@uefadigital/panenka/dist/loader';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import packageInfos from '../package.json';

import App from './App';
import store from './store';
import './utils/i18n';
import { Context } from './store/context';

import './styles/fpz.scss';

const { version } = packageInfos;

console.log(`Running version: ${version}`);

declare global {
  interface Window {
    definePanenka: boolean;
  }
}

if (window.definePanenka) {
  defineCustomElements();
}

const root = createRoot(document.getElementById('fpz-root'), { identifierPrefix: 'fpz' });

root.render(
  <Provider store={store} context={Context}>
    <Suspense>
      <App />
    </Suspense>
  </Provider>,
);
