import './gtm';

export default class GoogleTagManagerHelper {
  /**
   * Push somethig in dataLayer
   * @param {Object} object to push
   */
  static _push(obj) {
    if (!obj) return;

    window.dataLayer ??= [];
    window.dataLayer.push(obj);
  }

  /**
   * Push an Event
   * @param {string} category
   * @param {string} action
   * @param {string} [label='']
   * @param {object} [data={}]
   * @param {string} [name='']
   */
  static event(category, action, label = '', data = {}, name = 'performance_zone') {
    this._push({
      event: 'ga4Event',
      event_name: name,
      [name]: {
        event_category: category,
        event_action: action,
        event_label: label,
        ...data,
      },
    });
  }
}
