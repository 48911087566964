import {
  PkAccordionGroup,
  PkAccordionItem,
  PkAccordionItemTitle,
  PkAccordionItemContent,
  PkDivider,
} from '@uefadigital/panenka-react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';

import { actions } from '../../models';
import { loadRanksAction } from '../../models/rankings';
import { useSelector, useDispatch } from '../../store';
import type { PositionType, Team } from '../../types';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getOptions } from '../../utils/optionsManager';
import FilterCTA from '../FilterCTA';
import PositionBox from '../PositionBox';
import TeamBox from '../TeamBox';

import * as style from './style.module.scss';

import 'react-responsive-modal/styles.css';

const { competitionCode, showTeamsDisclaimer } = getOptions();

const Filter = () => {
  const { t } = useTranslation();
  const teams = useSelector((state) => state.teams.list);
  const positions = useSelector((state) => state.filter.positions);
  const filterVisible = useSelector((state) => state.filter.isVisible);
  const dispatch = useDispatch();
  const hideFilter = useCallback(() => dispatch(actions.filter.hideFilter()), [dispatch]);
  const updatePositions = useCallback((pos) => dispatch(actions.filter.setPositions(pos)), [dispatch]);
  const updateTeams = useCallback((tt) => dispatch(actions.teams.setTeams(tt)), [dispatch]);
  const resetOffset = useCallback(() => dispatch(actions.rankings.resetOffset()), [dispatch]);

  const [localPositions, setLocalPositions] = useState(positions);
  const [localTeams, setLocalTeams] = useState(teams);

  useEffect(() => {
    setLocalPositions(positions);
    setLocalTeams(teams);
  }, [positions, teams]);

  const onPositionItemClick = (position: PositionType, selected: boolean) => {
    setLocalPositions((positions) =>
      positions.map((p) => ({
        ...p,
        active: p.id === position.id ? selected : p.active,
      })),
    );
  };

  const onTeamItemClick = (team: Team, selected: boolean) => {
    setLocalTeams((teams) =>
      teams.map((ct) => ({
        ...ct,
        isFilter: ct.teamCode === team.teamCode && ct.id === team.id ? selected : ct.isFilter,
      })),
    );
  };

  const closeModal = () => {
    hideFilter();
  };

  const trackFilters = () => {
    const positions = localPositions.filter((p) => p.active).map((x) => x.id);
    const teams = localTeams.filter((p) => p.isFilter).map((x) => x.teamCode);
    const num = positions.length + teams.length;
    if (num > 0) {
      const tracks = positions.concat(teams).join(';');
      GoogleTagManagerHelper.event(
        'Click',
        `Select ${num} filters - ${tracks}`,
        'Performance Zone',
        { section_reference: 'fpz', interaction: `Select ${num} filters - ${tracks}` },
        'performance_zone',
      );
    }
  };

  const saveFilters = () => {
    trackFilters();
    updatePositions(localPositions);
    updateTeams(localTeams);
    dispatch(resetOffset());
    dispatch(loadRanksAction());
  };

  return (
    <div className={style.fpzFilter}>
      <Modal
        open={filterVisible}
        center={true}
        closeOnEsc={true}
        showCloseIcon={false}
        classNames={{
          overlay: `filter-overlay__wrapper pk-competition--${competitionCode}`,
          modal: `pk-theme--dark filter-overlay__modal pk-competition--${competitionCode} pk-pt--xl pk-pt-sm--xl2`,
        }}
        closeOnOverlayClick={true}
        onClose={closeModal}
      >
        <PkAccordionGroup multipleOpen>
          <PkAccordionItem expanded>
            <PkAccordionItemTitle>{t('filter_by_position')}</PkAccordionItemTitle>
            <PkAccordionItemContent>
              <div data-testid='fpz-filter__position' className={style.fpzFilterPosition}>
                {localPositions.map((pos) => (
                  <PositionBox key={`pos-${pos.id}`} position={pos} onItemClick={onPositionItemClick} />
                ))}
              </div>
            </PkAccordionItemContent>
          </PkAccordionItem>
          <PkAccordionItem expanded>
            <PkAccordionItemTitle>{t('filter_by_team')}</PkAccordionItemTitle>
            <PkAccordionItemContent>
              <div data-testid='fpz-filter__team' className={style.fpzFilterTeam}>
                {localTeams.map((ct) => (
                  <TeamBox key={`${ct.id}-${ct.teamCode}`} team={ct} onItemClick={onTeamItemClick} />
                ))}
              </div>
              {showTeamsDisclaimer && (
                <div className='pk-font--base-regular'>
                  <PkDivider direction='horizontal' className='pk-mb--m' />
                  <div className='pk-font-size--xs pk-font-size-sm--s pk-pb--xl pk-pb-sm--xl2 pk-text--text-02 pk-line-height--lg'>
                    {t('teams_disclaimer')}
                  </div>
                </div>
              )}
            </PkAccordionItemContent>
          </PkAccordionItem>
        </PkAccordionGroup>
        <FilterCTA onDone={saveFilters} />
      </Modal>
    </div>
  );
};

export { Filter as IsolatedFilter };
export default memo(Filter);
