import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';

import { customDispatchHook, customSelectorHook, customStoreHook } from './context';

export const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    hooks: {
      useDispatch: customDispatchHook,
      useSelector: customSelectorHook,
      useStore: customStoreHook,
    },
  }),
);
