import { domesticApi, rankingsApi } from '../utils/api/queries';

import { filter } from './filter';
import { rankings } from './rankings';
import { rankingsView } from './rankings-view';
import { route } from './route';
import { teams } from './teams';

export const reducer = {
  rankings: rankings.reducer,
  rankingsView: rankingsView.reducer,
  route: route.reducer,
  filter: filter.reducer,
  teams: teams.reducer,
  // Add the generated reducer as a specific top-level slice
  [rankingsApi.reducerPath]: rankingsApi.reducer,
  [domesticApi.reducerPath]: domesticApi.reducer,
};

export const actions = {
  rankings: rankings.actions,
  rankingsView: rankingsView.actions,
  route: route.actions,
  filter: filter.actions,
  teams: teams.actions,
};
