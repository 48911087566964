import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getOptions } from '../../utils/optionsManager';
import { injectScript, gptAddSize, makeCamelCase } from '../../utils/utils';

import * as classes from './style.module.scss';

const languageMap: { [key: string]: string } = Object.freeze({
  en: 'english',
  it: 'italian',
  fr: 'french',
  de: 'german',
  es: 'spanish',
  pt: 'portuguese',
  ru: 'russian',
});

export const GptBanner = ({
  slotType = 'leaderboard',
  position,
  adUnitPath,
}: {
  slotType: string;
  position: string;
  adUnitPath: string;
}) => {
  const mounted = useRef(true);

  const [hideSlot, setHideSlot] = useState(true);
  const { i18n } = useTranslation();
  const locale = i18n?.language;

  const randomId = useRef(crypto.randomUUID());
  const slot = useRef<googletag.Slot>();

  const currentLanguage = useMemo(() => {
    if (!locale) return languageMap['en'];

    if (languageMap[locale]) return languageMap[locale];

    if (locale.length > 2) return languageMap[locale.substring(0, 2)] ?? languageMap['en'];

    return languageMap['en'];
  }, [locale]);

  const adPath = useMemo(
    () =>
      adUnitPath.replace('{language}', currentLanguage).replace('{competitionBanner}', getOptions().competitionBanner),
    [adUnitPath, currentLanguage],
  );

  const onRender = useCallback(() => {
    if (slot.current) return;

    const gt = window.googletag ?? ({} as googletag.Googletag);
    gt.cmd = gt.cmd ?? [];
    gt.cmd.push(() => {
      let mappingBuilder = gt.sizeMapping();
      gt.pubads().disableInitialLoad(); // important
      gt.pubads().enableSingleRequest();
      mappingBuilder = gptAddSize(slotType, mappingBuilder);
      const mapping = mappingBuilder.build();

      if (slotType === 'slider' || slotType === 'slider-all') {
        slot.current = gt.defineOutOfPageSlot(adPath, randomId.current);
      } else {
        slot.current = gt.defineSlot(adPath, [0, 0], randomId.current);
      }

      if (!slot.current) return;

      const pos = position.includes('|') ? position.split('|') : position;
      slot.current
        .setCollapseEmptyDiv(true, true)
        .defineSizeMapping(mapping)
        .addService(gt.pubads())
        .setTargeting('pos', pos);

      if (!gt.pubadsReady) gt.enableServices();

      gt.display(randomId.current);
      gt.pubads().refresh();
    });
  }, [adPath, position, slotType]);

  useEffect(() => {
    injectScript('https://www.googletagservices.com/tag/js/gpt.js', 'gpt').then(() => {
      mounted.current && setHideSlot(false);
    });

    return () => {
      mounted.current = false;

      slot.current && window.googletag.destroySlots([slot.current]);
    };
  }, []);

  useEffect(() => {
    //I store the width to avoid unwanted resize event in safari
    let windowWidth = 0;

    const handleResize = () => {
      const gt = window.googletag ?? ({} as googletag.Googletag);
      if (window.innerWidth !== windowWidth) {
        mounted.current && gt.pubads && gt.pubads().refresh();
        windowWidth = window.innerWidth;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      mounted.current = false;
    };
  }, []);

  if (hideSlot) return null;

  return (
    <div data-testid='banner' className={clsx(classes.gptBannerWrap, classes[makeCamelCase(`gpt-banner-${slotType}`)])}>
      <div ref={onRender} id={randomId.current}></div>
    </div>
  );
};

export default GptBanner;
