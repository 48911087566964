import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import type { TypedUseSelectorHook } from 'react-redux';

import { reducer } from '../models';
import { rankingsApi, domesticApi } from '../utils/api/queries';

import { customDispatchHook, customSelectorHook } from './context';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([rankingsApi.middleware, domesticApi.middleware]),
});

export type CustomDispatch = typeof store.dispatch;
export type FPZState = ReturnType<typeof store.getState>;

export const useDispatch: () => CustomDispatch = customDispatchHook;
export const useSelector: TypedUseSelectorHook<FPZState> = customSelectorHook;

// configure listeners using the provided defaults
setupListeners(store.dispatch);

export default store;
