class EventBus {
  get eventBus() {
    return window.D3?.eventBus;
  }

  /**
   * Register an handler on the eventbus
   * @param key The key to call
   * @param callback The handler
   * @param context The context of the handler
   * @param args Additional argumens
   */
  on(key: string, callback: (...params: unknown[]) => unknown, context?: any, ...args: any[]) {
    this.eventBus && this.eventBus.on(key, callback, context, ...args);
  }

  /**
   * Remove one or more listener(s)
   * @param key The key of the listener(s) to remove
   * @param callback The handler
   * @param context The context of the handler
   */
  off(key: string, callback?: (data?: unknown) => unknown, context?: any): boolean {
    return this.eventBus && this.eventBus.off(key, callback, context);
  }

  /**
   * Dispatch a key
   * @param key The key name
   * @param args Additional arguments
   * @returns true if the key has been fired
   */
  dispatch(key: string, ...args: any[]): boolean {
    return this.eventBus && this.eventBus.dispatch(key, ...args);
  }

  /**
   * Check if there are listeners for a key
   * @param key The key name
   * @param target The target of the key
   * @returns true if the key has been fired
   */
  anybody(key: string): boolean {
    return this.eventBus && this.eventBus.anybody(key);
  }

  // eslint-disable-next-line no-use-before-define
  private static instance: EventBus;
  static getInstance(): EventBus {
    this.instance ??= new EventBus();
    return this.instance;
  }
}

export default EventBus.getInstance();
export type EventBusType = EventBus;
