import { PkChip } from '@uefadigital/panenka-react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { actions } from '../../models';
import { loadRanksAction } from '../../models/rankings';
import { useDispatch } from '../../store';
import GoogleTagManagerHelper from '../../utils/googleTagManager';

import * as styles from './style.module.scss';

export const FilterLabel = ({ type, item }) => {
  const { t, i18n } = useTranslation();

  const language = i18n?.language;
  const text = useMemo(
    () => (type === 'team' ? item?.translations?.displayName[language.toUpperCase()] : t(item?.id)),
    [item?.id, item?.translations?.displayName, language, t, type],
  );

  const dispatch = useDispatch();
  const togglePosition = useCallback((pos) => dispatch(actions.filter.togglePosition(pos)), [dispatch]);
  const toggleTeam = useCallback((team) => dispatch(actions.teams.toggleTeam(team)), [dispatch]);
  const resetOffset = useCallback(() => dispatch(actions.rankings.resetOffset()), [dispatch]);

  const onClick = () => {
    GoogleTagManagerHelper.event(
      'Click',
      'Clear filter',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Clear filter' },
      'performance_zone',
    );
    switch (type) {
      case 'team':
        toggleTeam(item);
        break;
      case 'position':
        togglePosition(item.id);
        break;
      default:
        break;
    }
    dispatch(resetOffset());

    dispatch(loadRanksAction());
  };

  return (
    <PkChip type='filter' className={styles.chip} onPkClick={onClick}>
      {text}
    </PkChip>
  );
};

export default memo(FilterLabel);
