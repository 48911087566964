import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import ReactModal from 'react-modal';

const Modal: FC<{ children: ReactNode; showModal?: boolean; className?: string; overlayClassName?: string }> = ({
  children,
  showModal,
  className,
  overlayClassName,
}) => (
  <div>
    <ReactModal
      isOpen={showModal}
      overlayClassName={`modal.open modal-container ${overlayClassName}`}
      className={className}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  </div>
);

export default memo(Modal);
