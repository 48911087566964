export type AppOptions = {
  competitionId?: string;
  clubCompetition?: boolean;
  seasonYear?: string;
  competitionCode?: string;
  competitionGroup?: string;
  applicationLanguage?: string;
  competitionUrl?: string;
  competitionBanner?: string;
  competitionTracking?: string;
  titleVocab?: string;
  optimizeExperimentId?: string;
  phase?: string;
  sponsorPhase?: string;
  appMode?: 'overview' | 'ranking';
  theme?: string;
  showFavCta?: boolean;
  showFavouriteCta?: boolean;
  showTop3?: boolean;
  showTop3Carousel?: boolean;
  showOtherItems?: boolean;
  showTeamsDisclaimer?: boolean;
};

const defaultOptions: AppOptions = {
  competitionId: '1',
  clubCompetition: true,
  seasonYear: 'current',
  competitionCode: 'ucl',
  competitionGroup: 'ucl',
  applicationLanguage: 'en',
  competitionUrl: 'uefachampionsleague',
  competitionTracking: 'uefachampionsleague',
  competitionBanner: 'ucl',
  titleVocab: 'player_ranking_ucl',
  optimizeExperimentId: undefined,
  phase: 'ALL',
  sponsorPhase: 'TOURNAMENT',
  theme: 'light',
  showFavouriteCta: false,
  showTop3: true,
  showTop3Carousel: true,
  showOtherItems: true,
  appMode: 'ranking',
  showTeamsDisclaimer: false,
};

export const getOptions = () => {
  let options = defaultOptions;
  const container = document.querySelector<HTMLElement>('#fpz-root');
  if (container && container.dataset && container.dataset.options) {
    options = {
      ...options,
      ...JSON.parse(container.dataset.options),
    };
  }

  return options;
};
