import { PkButton } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useOptimize } from '../../hooks/useOptimize';
import { useSelector } from '../../store';
import EventBus from '../../utils/EventBus';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getOptions } from '../../utils/optionsManager';
import { makeCamelCase } from '../../utils/utils';

import * as style from './style.module.scss';

const { optimizeExperimentId } = getOptions();

const FavouriteTeamCTA: FC = () => {
  const { t } = useTranslation();
  const preferredTeam = useSelector((state) => state.teams.preferred);
  const variant = useOptimize(optimizeExperimentId);
  const variantModifier = variant ? `-variant` : '';

  const handleBtnClick = () => {
    EventBus.dispatch('openPreferredTeam');
    GoogleTagManagerHelper.event(
      'Click',
      'Fav team selector - Loaded from card',
      'Favourite Team Selection',
      {
        section_reference: 'Favourite Team Selection',
      },
      'fav_team_selector_loaded_from_card',
    );
  };

  if (preferredTeam) return null;

  return (
    <div
      className={clsx(
        style.favTeamCta,
        style[makeCamelCase(`fav-team-cta-${variantModifier}`)],
        'pk-container',
        'pk-theme-dark',
      )}
    >
      <div className={clsx(style.favTeamCtaSpacer, 'pk-col', 'pk-col--span-12', 'pk-col--span-md-6')}></div>
      <div className={clsx(style.favTeamCtaContainer, 'pk-col', 'pk-col--span-12', 'pk-col--span-md-6')}>
        <h3 className={style.favTeamCtaTitle}>{t(`favourite-team_title${variantModifier}`)}</h3>
        <p className={style.favTeamCtaText}>{t(`favourite-team_text${variantModifier}`)}</p>

        <div className={style.favTeamCtaButtonWrapper}>
          <PkButton className={style.pkButton} appearance='outlined' type='button' onClick={handleBtnClick}>
            {t('select_team')}
          </PkButton>
        </div>
      </div>
    </div>
  );
};

export default memo(FavouriteTeamCTA);
