import { PkButton } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { actions } from '../../models';
import { useSelector, useDispatch } from '../../store';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getActiveTeams, getActivePositions } from '../../utils/misc';
import { getOptions } from '../../utils/optionsManager';

import * as style from './style.module.scss';

export const Header = () => {
  const dispatch = useDispatch();
  const showFilter = useCallback(() => dispatch(actions.filter.showFilter()), [dispatch]);
  const { appMode, competitionUrl } = getOptions();

  const teamsState = useSelector((state) => state.teams);
  const filterState = useSelector((state) => state.filter);
  const count = getActiveTeams(teamsState).length + getActivePositions(filterState).length;
  const { t } = useTranslation();
  const clickFilter = () => {
    GoogleTagManagerHelper.event(
      'Click',
      'Open filter',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Open filter' },
      'performance_zone',
    );
    GoogleTagManagerHelper._push({
      event: 'Page Meta',
      pageName: `/${competitionUrl}/performancezone/filter/`,
      pageType: 'filter',
      pageSubType: 'FPZ',
      contentSection3: 'filter',
    });
    GoogleTagManagerHelper._push({
      event: 'virtPath',
      virtPath: `/${competitionUrl}/performancezone/filter/`,
    });
    showFilter?.();
  };

  return (
    <div className={clsx('pk-col', 'pk-col--span-12', style.fpzHeader)}>
      {appMode === 'overview' && (
        <header className='section--header '>
          <h2 className={style.fpzHeaderTitle}>{t('top-performers')}</h2>
        </header>
      )}
      {appMode !== 'overview' && (
        <div className={style.fpzHeaderButton}>
          <PkButton className={clsx(style.pkButton, 'pk-button')} appearance='outlined' onPkClick={clickFilter}>
            {t('filter')}
            {count ? ` (${count})` : ''}
          </PkButton>
        </div>
      )}
    </div>
  );
};

export default Header;
