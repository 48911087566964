export const positions = [
  {
    id: 'goalkeeper',
    active: false,
  },
  {
    id: 'defender',
    active: false,
  },
  {
    id: 'midfielder',
    active: false,
  },
  {
    id: 'forward',
    active: false,
  },
];
