import { PkButton } from '@uefadigital/panenka-react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ActiveFilters from '../../components/ActiveFilters';
import CarouselRanks from '../../components/CarouselRanks';
import FavouriteTeamCTA from '../../components/FavouriteTeamCTA';
import GptBanner from '../../components/GptBanner';
import Header from '../../components/Header';
import ItemDisplayer from '../../components/ItemDisplayer';
import Modal from '../../components/Modal';
import NoResults from '../../components/NoResults';
import Top3 from '../../components/Top3';
import { actions } from '../../models';
import { loadRanksAction } from '../../models/rankings';
import { useSelector, useDispatch } from '../../store';
import { noFilters, mainAppParams, isIV } from '../../utils/misc';
import { getOptions } from '../../utils/optionsManager';
import withGlobalSelectorScope from '../../utils/withGlobalSelectorScope';

const { showFavouriteCta, showTop3, showTop3Carousel, appMode, showOtherItems } = getOptions();

const Main = () => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const globalRanks = useSelector((state) => state.rankings.globalRanks);
  const positionsState = useSelector((state) => state.filter);
  const teamsState = useSelector((state) => state.teams);
  const noFiltersActive = noFilters(positionsState, teamsState);
  const dispatch = useDispatch();
  const resetOffset = useCallback(() => dispatch(actions.rankings.resetOffset()), [dispatch]);

  useEffect(() => {
    dispatch(resetOffset());
    dispatch(loadRanksAction());
    const params = new URLSearchParams(location?.search);
    if (params?.get('ref')) {
      mainAppParams.iv = true;
    }
  }, [location?.search, dispatch, resetOffset]);

  const content = () => {
    const language = i18n?.language;

    const noResults = globalRanks.length === 0;
    const topItems = globalRanks.slice(0, 6);
    const otherItems = globalRanks.slice(6);

    return (
      <div className={`lang-${language}`}>
        <div className={`fpz-Main fzp-${appMode}`}>
          <div className='pk-container fpz-header-container'>
            <Header />
          </div>
          {!noFiltersActive && (
            <div className='pk-container fpz-filters-container'>
              <ActiveFilters />
            </div>
          )}
          {noResults && (
            <div className='pk-container'>
              <NoResults />
            </div>
          )}
          <div className='pk-container fpz-playersPart1-container'>
            {!!topItems?.length && <ItemDisplayer items={topItems} />}
            {appMode === 'overview' && (
              <div className='pk-col pk-col pk-col--span-12'>
                <PkButton link-button='true' appearance='outlined' className={'pk-button'} link-href='./ranking'>
                  {t('see_full_ranking')}
                </PkButton>
              </div>
            )}
          </div>
          <GptBanner
            slotType='leaderboard'
            adUnitPath={'/5874/{competitionBanner}.{language}/exclusive_fedex'}
            position='top'
          />
          {showTop3Carousel && <CarouselRanks />}
          {showFavouriteCta && <FavouriteTeamCTA />}
          {showTop3 && noFiltersActive && <Top3 />}
          {showOtherItems && !!otherItems?.length && (
            <div className='pk-container fpz-playersPart2-container'>
              <ItemDisplayer items={otherItems} />
            </div>
          )}
        </div>
      </div>
    );
  };

  if (isIV()) {
    return (
      <Modal showModal={true} className={'fpz_modal_content pk-theme-light'} overlayClassName={'fpz_modal'}>
        {content()}
      </Modal>
    );
  } else return content();
};

const globalSelectorScope = withGlobalSelectorScope({ globalSelector: '.fpz-App' })(Main);

export default globalSelectorScope;
