import { TinyColor } from '@ctrl/tinycolor';

export const getColorLightDark = (hexColor: string, invert = false): 'dark' | 'light' => {
  const light = new TinyColor(hexColor).isLight();

  if (invert) return light ? 'dark' : 'light';

  return light ? 'light' : 'dark';
};

export const getTextColor = (hexColor: string) => `var(--uefacom-text-color--${getColorLightDark(hexColor, true)})`;

export const getCssVariableValue = (varName: string, element = document.documentElement, prefix = 'uefacom') =>
  getComputedStyle(element).getPropertyValue(prefix ? `--${prefix}-${varName}` : varName);

export const setCssVariable = (
  varName: string,
  value: string,
  element = document.documentElement,
  prefix = 'uefacom',
) => {
  element.style.setProperty(prefix ? `--${prefix}-${varName}` : varName, value);
};
