import gtag from 'ga-gtag';
import { useEffect, useState } from 'react';

export const useOptimize = (experimentId?: string) => {
  const [variant, setVariant] = useState<number | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get('variant');
    setVariant(value ? Number.parseInt(value) : null);
  }, []);

  useEffect(() => {
    gtag('event', 'optimize.callback', {
      name: experimentId,
      callback: (value: string, name: string) => {
        console.log(`Experiment with ID: ${name} is on variant: ${value}`);
        setVariant(value ? Number.parseInt(value) : null);
      },
    });
  }, [experimentId]);

  return variant;
};
