import { PkIdentifier, PkBadge } from '@uefadigital/panenka-react';
import { memo } from 'react';

import type { Team, TeamStateType } from '../../types';

import * as style from './style.module.scss';

const TeamBox = ({
  team,
  onItemClick,
}: {
  team: TeamStateType;
  onItemClick?: (t: Team, selected: boolean) => void;
}) => {
  const handleClick = (
    event: CustomEvent<{
      selected: boolean;
    }>,
  ) => {
    onItemClick && onItemClick(team, event.detail.selected);
  };

  return (
    <PkIdentifier
      data-testid='identifier'
      className={style.pkIdentifier}
      enableSelection={true}
      selected={team.isFilter}
      type='vertical'
      primarySize='14'
      onPkChange={handleClick}
    >
      <span slot='prefix'>
        <PkBadge
          alt={team.internationalName}
          badgeTitle={team.internationalName}
          fallbackImage='club-generic-badge'
          src={team.logoUrl}
        ></PkBadge>
      </span>
      <span slot='primary'>{team.internationalName}</span>
    </PkIdentifier>
  );
};

export default memo(TeamBox);
