import { createSlice } from '@reduxjs/toolkit';

import type { RouteStateType } from '../types';
// TODO: remove this reducer once the parmeters will be manged by routing

const initialState: RouteStateType = {
  teamId: null,
  playerCountryCode: null,
  position: null,
  teamCountryCode: null,
};

export const route = createSlice({
  name: 'route',
  initialState: initialState,
  reducers: {
    setTeamId: (state, { payload }) => {
      state.teamId = payload;
    },
    setPosition: (state, { payload }) => {
      state.position = payload;
    },
    setPlayerCountryCode: (state, { payload }) => {
      state.playerCountryCode = payload;
    },
    setTeamCountryCode: (state, { payload }) => {
      state.teamCountryCode = payload;
    },
    resetRoute: (state) => {
      const newState = {
        ...state,
        teamId: null,
        playerCountryCode: null,
        position: null,
        teamCountryCode: null,
      };
      return newState;
    },
  },
});
