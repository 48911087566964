import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { env } from './enviroments';
import { getOptions } from './optionsManager';

const LANGUAGES = ['en', 'de', 'es', 'fr', 'it', 'pt', 'ru'];

const { applicationLanguage } = getOptions();
let locale = 'en';
if (applicationLanguage) {
  const loc = applicationLanguage;
  locale = loc && LANGUAGES.includes(loc) ? loc : 'en';
}

const ns = ['328590'];
const options = {
  lng: locale,
  ns,
  defaultNS: ns[0],
  backend: {
    loadPath: `${env.uefa}/vocabulary/{{ns}}/{{lng}}`,
    crossDomain: true,
    overrideMimeType: true,
    parse: (data) => JSON.parse(data)[(i18n as any).isLanguageChangingTo],
  },
  interpolation: {
    escapeValue: false, //disabled for React
  },
  debug: import.meta.env.MODE === 'development',
  fallbackLng: 'en',
};

i18n.use(HttpApi);

i18n.use(initReactI18next).init(options);

export default i18n;
