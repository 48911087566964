import type { Team, League } from '@uefadigital/panenka';

import type { SponsorApiType, SponsorsToolResponseType, SponsorType, RankingType, CountryType } from '../../types';
import { getCountryCode } from '../cookies';
import i18n from '../i18n';

import { endpoints } from './endpoints';
import { cachedFetch } from './fetch';
import { createRankingsURL } from './utils';

export const getSponsorFromApiResponses: (
  result: SponsorsToolResponseType,
  resultSponsorApi: SponsorApiType[],
) => SponsorApiType | undefined = (result, resultSponsorApi) => {
  const toolSponsor = result?.sponsors?.[0]?.sponsor;
  const pageSponsorCleaned = toolSponsor?.toLowerCase().replace(/[\W_]+/g, '');
  const newSponsor: SponsorApiType | undefined = resultSponsorApi.find(
    (s: SponsorApiType) => s.mainSponsor === pageSponsorCleaned,
  );
  return newSponsor;
};

export const getSponsorFromApiResponsesAndTag: (
  resultSponsorApi: SponsorApiType[],
  tag: string,
) => SponsorApiType | undefined = (resultSponsorApi, tag) => {
  const newSponsor: SponsorApiType | undefined = resultSponsorApi?.find((s: SponsorApiType) => s.tags?.includes(tag));
  return newSponsor;
};

export const getLabel: (sponsor: SponsorApiType) => string = (sponsor) => {
  const lang = i18n.language?.toUpperCase() ?? 'EN';
  if (sponsor?.introText?.translations?.[lang]) return sponsor.introText.translations[lang];
  if (sponsor?.introText?.translations?.['en']) return sponsor.introText.translations['en'];
  return '';
};

export const getLink: (sponsor: SponsorApiType) => string = (sponsor) => {
  const lang = i18n.language?.toUpperCase() ?? 'EN';
  if (sponsor?.links?.[lang]) return sponsor.links[lang];
  if (sponsor?.links?.['EN']) return sponsor.links['EN'];
  return '';
};

export const getSponsorFromAPI: (name: string) => Promise<SponsorType> = async (name) => {
  const apiResponse = await cachedFetch<SponsorApiType[]>(`${endpoints.sponsorService}`);
  const sponsorApiObj: SponsorApiType = getSponsorFromApiResponsesAndTag(apiResponse, name);
  const label = getLabel(sponsorApiObj);
  const link = getLink(sponsorApiObj);

  const sponsor: SponsorType = {
    url: link,
    retinaSrc: sponsorApiObj?.image,
    bgColor: sponsorApiObj?.colour,
    textColor: sponsorApiObj?.secondaryColour ?? '--pk-sponsor--fedex-01',
    tag: label,
    code: sponsorApiObj?.code,
  };
  return sponsor;
};

export const loadRanksByPositions: (position: string) => Promise<RankingType[]> = async (position) => {
  const url = createRankingsURL(endpoints.rankings, null, { fieldPosition: position, limit: 3 });
  const response = (await cachedFetch<RankingType[]>(url)) ?? [];
  return response?.filter((value) => value.rank.totalPoints > 0) ?? [];
};

export const loadRanksByTeamCountryCode: (countryCode?: string, limit?: number) => Promise<RankingType[]> = async (
  countryCode,
  limit = 100,
) => {
  countryCode ??= await getCountryCode();
  const url = createRankingsURL(endpoints.rankings, null, { limit, teamCountryCode: countryCode });

  const response = (await cachedFetch<RankingType[]>(url)) ?? [];
  return response?.filter((value) => value.rank.totalPoints > 0) ?? [];
};

export const loadTop3RanksByTeamCountryCode = (countryCode?: string) => loadRanksByTeamCountryCode(countryCode, 3);

export const loadRanksByPlayerCountryCode: (countryCode?: string, limit?: number) => Promise<RankingType[]> = async (
  countryCode,
  limit = 100,
) => {
  countryCode ??= await getCountryCode();
  const url = createRankingsURL(endpoints.rankings, null, { limit, playerCountryCode: countryCode });

  const response = (await cachedFetch<RankingType[]>(url)) ?? [];
  return response?.filter((value) => value.rank.totalPoints > 0) ?? [];
};

export const loadTop3RanksByPlayerCountryCode = (countryCode?: string) => loadRanksByPlayerCountryCode(countryCode, 3);

export const loadRanksByTeamId: (id: string, limit?: number) => Promise<RankingType[]> = async (id, limit = 100) => {
  const url = createRankingsURL(endpoints.rankings, null, { limit, teamId: id });

  const response = (await cachedFetch<RankingType[]>(url)) ?? [];
  return response?.filter((value) => value.rank.totalPoints > 0) ?? [];
};

export const loadTop3RanksByTeamId = (id: string) => loadRanksByTeamId(id, 3);

export const loadTeam: (teamId: string) => Promise<Team> = async (teamId: string) => {
  const url = `${endpoints.team}?teamIds=${teamId}`;
  const response = (await cachedFetch(url)) ?? [];
  return response?.[0];
};
export const loadLeague: (countryCode: string) => Promise<League> = async (countryCode) => {
  countryCode ??= await getCountryCode();
  let url = endpoints.league;
  url += `&countryCode=${countryCode}`;
  const response = (await cachedFetch(url)) ?? [];
  return response?.[0];
};

export const loadCountry: (countryCode?: string) => Promise<CountryType> = async (countryCode) => {
  countryCode ??= await getCountryCode();
  const url = endpoints.country;
  const response = (await cachedFetch<CountryType[]>(url)) ?? [];
  return response?.find((c) => c.fifaCode === countryCode);
};
