import type { Team } from '@uefadigital/panenka';
import { PkAvatar, PkBadge, PkButton, PkDataCard, PkIcon, PkIdentifier, PkListItem } from '@uefadigital/panenka-react';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLeague } from '../../hooks/useLeague';
import { useReset } from '../../hooks/useReset';
import type { rankType } from '../../pages/Ranks';
import type { CountryType, RankingType } from '../../types';
import { loadCountry } from '../../utils/api';
import ErrorBoundary from '../../utils/ErrorBoundary';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getTranslatedProp } from '../../utils/l10n';
import { getContext, getPlayerLink } from '../../utils/misc';
import { getOptions } from '../../utils/optionsManager';

import * as style from './style.module.scss';

const { clubCompetition } = getOptions();

const CarouselCard: FC<{
  items: RankingType[];
  type: string;
  team?: Team;
  countryCode?: string;
}> = ({ items, type, team, countryCode }) => {
  const { t } = useTranslation();

  const [country, setCountry] = useState<CountryType>();
  const { resetRoute } = useReset();
  const { league } = useLeague(countryCode, type as rankType);
  useEffect(() => {
    if (type === 'country') {
      loadCountry(countryCode).then((c) => {
        setCountry(c);
      });
    }
  }, [countryCode, type]);

  const title = useMemo(() => {
    switch (type) {
      case 'country':
        return getTranslatedProp(country?.translations?.countryName);
      case 'team': {
        return getTranslatedProp(team?.translations?.displayName);
      }
      case 'league':
        return getTranslatedProp(league?.translations?.name);
      case 'FORWARD':
      case 'MIDFIELDER':
      case 'DEFENDER':
      case 'GOALKEEPER':
        return t('top_field_position')?.replace('{fieldPosition}', t(type?.toLowerCase()));
      default:
        break;
    }
  }, [country?.translations?.countryName, league?.translations?.name, t, team?.translations?.displayName, type]);

  const buttonClicked = () => {
    resetRoute();
    GoogleTagManagerHelper.event(
      'Click',
      'Show more top 3',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Show more top 3' },
      'performance_zone',
    );
  };
  const link = useMemo(() => {
    if (type === 'team' && clubCompetition) return `#/${type}/${team?.id}`;
    if (type === 'FORWARD' || type === 'MIDFIELDER' || type === 'DEFENDER' || type === 'GOALKEEPER')
      return `#/position/${type}`;
    return `#/${type}/${countryCode}`;
  }, [countryCode, team, type]);

  if (!items?.length) return null;

  return (
    <ErrorBoundary>
      <PkDataCard height='100%'>
        <div slot='header'>
          <div className={style.cardHeading}>{t(title)}</div>
        </div>
        <div slot='body'>
          {items?.map(
            (item) =>
              item?.player && (
                <PkListItem hasDivider={false} key={`${title}_${item.player.id}`}>
                  <span slot='prefix-card'>{item.rank.overallRank}</span>
                  <a href={getPlayerLink(item.player.id)}>
                    <PkIdentifier>
                      <span slot='prefix' className='pk-mr--8 pk-py--xs2'>
                        <PkAvatar
                          type='image'
                          fallbackImage='generic-head'
                          src={getContext(item.player)?.images?.imageUrl}
                        >
                          <PkBadge
                            slot='badge'
                            alt={getTranslatedProp(item.player?.team?.['names'])}
                            badgeTitle={getTranslatedProp(item.player?.team?.['names'])}
                            size={20}
                            fallbackImage='club-generic-badge'
                            src={item.player?.team?.['logos'].SMALL}
                          ></PkBadge>
                        </PkAvatar>
                      </span>
                      <span slot='primary'>{getTranslatedProp(item.player.shortNames)}</span>
                      <span slot='secondary'>{getTranslatedProp(item.player, 'fieldPositionDisplayName')}</span>
                    </PkIdentifier>
                  </a>
                  <div slot='suffix-card'>{item.rank.totalPoints}</div>
                </PkListItem>
              ),
          )}
        </div>
        <PkButton slot='cta' appearance='text' linkHref={link} onClick={buttonClicked} className={style.bottomLink}>
          <span>{t('top3_button')}</span>
          <PkIcon name='navigation-chevron-right' color='interaction'></PkIcon>
        </PkButton>
      </PkDataCard>
    </ErrorBoundary>
  );
};

export default CarouselCard;
