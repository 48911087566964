import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RankingType } from '../types';
import { endpoints } from '../utils/api/endpoints';
import { cachedFetch, formatUrl } from '../utils/api/fetch';
import { defaultParams, getPlayerCountryCode, getTeamCountryCode } from '../utils/misc';

const createRankingsParamsForView = (state?: any) => {
  let params = {
    ...defaultParams,
  };

  if (state) {
    const position = state.route.position;
    const teamId = state.route.teamId;
    const playerCountryCode = getPlayerCountryCode(state.route);
    const teamCountryCode = getTeamCountryCode(state.route);
    params = {
      ...params,
      fieldPosition: position?.toUpperCase(),
      teamId: teamId,
      playerCountryCode: playerCountryCode,
      teamCountryCode: teamCountryCode,
      limit: state.rankingsView.limit,
      offset: state.rankingsView.offset,
    };
  }

  return params;
};

export const loadRanksViewAction: AsyncThunk<RankingType[], void, unknown> = createAsyncThunk(
  'rankings-view/load',
  async (_, { getState }) => {
    const state = getState();
    const params = createRankingsParamsForView(state);
    const url = formatUrl(endpoints.rankings, params);
    const response = (await cachedFetch<RankingType[]>(url)) ?? [];
    return response?.filter((value) => value.rank.totalPoints > 0) ?? [];
  },
);

const initialState = {
  isLoading: false,
  items: [],
  offset: 0,
  limit: 20,
};

export const rankingsView = createSlice({
  name: 'ranks-view',
  initialState: initialState,
  reducers: {
    setOffset: (state) => {
      if (state.items.length >= state.offset + state.limit) {
        state.offset += state.limit;
      }
    },
    resetOffset: (state) => {
      state.offset = 0;
      state.items = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRanksViewAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadRanksViewAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (!payload) payload = [];
      if (!state.items) state.items = [];
      //I filter out the ranks that are already in the lasts element of the state to avoid duplicates
      const lastIds = state.items?.slice(-state.limit).map((ct) => ct.player.id);
      payload.filter((ct) => !lastIds?.includes(ct.player.id)).forEach((ct) => state.items.push(ct));
    });
  },
});
