import { useCallback } from 'react';

import { actions } from '../models';
import { useDispatch } from '../store';

export const useReset = () => {
  const dispatch = useDispatch();
  const resetPositions = useCallback(() => dispatch(actions.filter.resetFilters()), [dispatch]);
  const resetRoute = useCallback(() => dispatch(actions.route.resetRoute()), [dispatch]);
  const resetTeams = useCallback(() => dispatch(actions.teams.reset()), [dispatch]);
  const resetOffset = useCallback(() => dispatch(actions.rankings.resetOffset()), [dispatch]);
  const resetViewOffset = useCallback(() => dispatch(actions.rankingsView.resetOffset()), [dispatch]);

  return {
    resetPositions,
    resetTeams,
    resetRoute,
    resetOffset,
    resetViewOffset,
  };
};
