import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { Item } from '../../types';
import { getTranslatedProp } from '../../utils/l10n';
import { getContext } from '../../utils/misc';
import PlayerPicture from '../PlayerPicture';
import PlayerRank from '../PlayerRank';

import * as style from './style.module.scss';

export const PlayerCardMini = ({ item }: { item: Item }) => {
  const { t } = useTranslation();
  return (
    <a href={`${window.location.pathname}${item.player.id}/stats/`}>
      <div className={clsx(style.playerCardMini, 'h-100')}>
        <div className={style.playerCardMiniPictureWrapper}>
          <PlayerPicture
            pic={getContext(item.player)?.images?.imageUrl}
            playerName={getTranslatedProp(item.player.names)}
          />
        </div>
        <div className={clsx(style.playerCardMiniInfo, 'pk-theme--light')}>
          <p className={style.playerCardMiniInfoName}>{getTranslatedProp(item.player, 'names')}</p>
          {item.player.team && (
            <div className={style.playerCardMiniInfoClub}>
              <img
                loading='lazy'
                className={style.playerCardMiniInfoClubLogo}
                alt={getTranslatedProp(item.player.team?.teamCodes)}
                src={item.player.team?.logos?.SMALL}
              />
              <p className={style.playerCardMiniInfoClubName}>{getTranslatedProp(item.player.team, 'names')}</p>
            </div>
          )}
          <p className={style.playerCardMiniInfoPosition}>
            {getTranslatedProp(item.player, 'fieldPositionDisplayName')}
          </p>
          <p className={style.playerCardMiniInfoPoints}>
            {Math.round(item.rank.totalPoints)} {t('pts')}
          </p>
        </div>
        <div className={style.playerCardMiniRankWrapper}>
          <PlayerRank data={item.rank} />
        </div>
      </div>
    </a>
  );
};

export default PlayerCardMini;
