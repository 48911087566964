const host = window.location.hostname;

let appEnv = 'prod';
if (host.includes('.uefa.com')) {
  if (host.includes('.int.') || host.includes('-int') || host.includes('.stg.')) appEnv = 'int';
  else if (host.includes('.pre.') || host.includes('-pre') || host.includes('.gold.')) appEnv = 'pre';
}

if (host === 'localhost' || host.includes('ngrok')) appEnv = 'dev';

const dev = {
  performances: 'https://fsp-players-ranking-service.int.uefa.com',
  competitions: 'https://comp.int.uefa.com',
  domestic: 'https://domestic.int.uefa.com',
  digital: 'https://editorial-int.uefa.com/api',
  uefa: 'https://www.int.uefa.com/api/v1',
  sponsor: 'https://fsp-sponsor-service.int.uefa.com/',
};

const int = {
  performances: 'https://fsp-players-ranking-service.int.uefa.com',
  competitions: 'https://comp.int.uefa.com',
  domestic: 'https://domestic.int.uefa.com',
  digital: 'https://editorial-int.uefa.com/api',
  uefa: 'https://www.int.uefa.com/api/v1',
  sponsor: 'https://fsp-sponsor-service.int.uefa.com/',
};

const pre = {
  performances: 'https://fsp-players-ranking-service.pre.uefa.com',
  competitions: 'https://comp.pre.uefa.com',
  domestic: 'https://domestic.pre.uefa.com',
  digital: 'https://editorial-pre.uefa.com/api',
  uefa: 'https://www.pre.uefa.com/api/v1',
  sponsor: 'https://fsp-sponsor-service.pre.uefa.com/',
};

const prod = {
  performances: 'https://fsp-players-ranking-service.uefa.com',
  competitions: 'https://comp.uefa.com',
  domestic: 'https://domestic.uefa.com',
  digital: 'https://editorial.uefa.com/api',
  uefa: 'https://www.uefa.com/api/v1',
  sponsor: 'https://fsp-sponsor-service.uefa.com/',
};

const list = {
  dev,
  int,
  pre,
  prod,
};

export const env = list[appEnv];
