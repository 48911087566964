import { useTranslation } from 'react-i18next';

import * as style from './style.module.scss';

export const NoResults = () => {
  const { t } = useTranslation();
  return (
    <div className={style.noResults}>
      <p className={style.noResultsImage}></p>
      <p className={style.noResultsText}>{t('no_results')}</p>
    </div>
  );
};

export default NoResults;
