import type { League } from '@uefadigital/panenka';
import { useEffect, useState } from 'react';

import type { rankType } from '../pages/Ranks';
import { loadLeague } from '../utils/api';

export const useLeague = (countryCode: string, type: rankType) => {
  /** Once fixed the test, this code could be replaced with the RTK Query hook */
  const [league, setLeague] = useState<League>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (type === 'league') {
      setIsLoaded(false);
      loadLeague(countryCode).then((c) => {
        if (mounted) {
          setLeague(c);
          setIsLoaded(true);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [countryCode, type]);

  return {
    league,
    isLoaded,
  };
};
