import type { Team } from '@uefadigital/panenka';

export const injectScript = (src: string, id: string) => {
  if (!id) id = crypto.randomUUID();
  return new Promise<void>((resolve, reject) => {
    let script: any = document.getElementById(id);

    if (script) return resolve();

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = id;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const gptAddSize = (slotType: string, mapping: any) => {
  if (slotType === 'billboard') {
    mapping = mapping
      .addSize(
        [1024, 0],
        [
          [728, 90],
          [970, 31],
          [970, 250],
          [970, 90],
          [970, 550],
        ],
      ) //Billboard mapping
      .addSize([0, 0], []); //Show nothing mapping
  } else if (slotType === 'slider') {
    mapping = mapping
      .addSize([1024, 0], []) //Show nothing mapping
      .addSize([0, 0], [1, 1]); //Slider mapping
  } else if (slotType === 'slider-all') {
    //Slider empty mapping
  } else if (slotType === 'mpu') {
    mapping = mapping.addSize([0, 0], [300, 250]);
  } else {
    mapping = mapping
      .addSize([1024, 0], [728, 90]) //Leaderboard mapping
      .addSize([0, 0], [320, 50]); //Smartphone mapping
  }
  return mapping;
};

export const getNumberOfTeamsPlayingInCountry: (teams: Team[], countryCode: string) => number = (
  teams,
  countryCode,
) => {
  return teams.reduce((acc, team) => (team.countryCode === countryCode ? acc + 1 : acc), 0);
};

export const makeCamelCase = (str: string) => str.replace(/[-_]+(\w)/g, (_, c) => c.toUpperCase());
