import { PkButton, PkCard, PkCarousel } from '@uefadigital/panenka-react';
import hash from 'object-hash';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTop3Players } from '../../hooks/useTop3Players';
import { useSelector } from '../../store';
import type { RankingType } from '../../types';
import {
  loadTop3RanksByPlayerCountryCode,
  loadTop3RanksByTeamCountryCode,
  loadTop3RanksByTeamId,
} from '../../utils/api';
import { getCountryCode } from '../../utils/cookies';
import ErrorBoundary from '../../utils/ErrorBoundary';
import EventBus from '../../utils/EventBus';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getOptions } from '../../utils/optionsManager';
import { getNumberOfTeamsPlayingInCountry } from '../../utils/utils';
import CarouselCard from '../CarouselCard';

import * as style from './style.module.scss';

const { clubCompetition } = getOptions();

const item0 = 1.1;
const item440 = 1.2;
const item768 = 2.2;
const item960 = 3.3;
const item1200 = 3.4;
const item1440 = 4.2;
const breakpoints = {
  '0': { slidesPerView: item0, slidesPerGroup: 1, spaceBetween: 16 },
  '440': { slidesPerView: item440, slidesPerGroup: 1, spaceBetween: 16 },
  '768': { slidesPerView: item768, slidesPerGroup: 2, spaceBetween: 16 },
  '960': { slidesPerView: item960, slidesPerGroup: 3, spaceBetween: 32 },
  '1200': { slidesPerView: item1200, slidesPerGroup: 3, spaceBetween: 32 },
  '1440': { slidesPerView: item1440, slidesPerGroup: 4, spaceBetween: 32 },
};

const CarouselRanks = () => {
  const { t } = useTranslation();
  const selectedTeam = useSelector((state) => state.teams.selected);
  const preferredTeam = useSelector((state) => state.teams.preferred);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const teams = useSelector((state) => state.teams.list);
  const [top3ByTeam, setTop3ByTeam] = useState<RankingType[]>(null);
  const [top3ByPlayer, setTop3ByPlayer] = useState<RankingType[]>(null);
  const [top3ByUserClub, setTop3ByUserClub] = useState<RankingType[]>(null);
  const [isTheOnlyTeamOfMyCountry, setIsTheOnlyTeamOfMyCountry] = useState(false);
  const { top3Forwards, top3Defenders, top3Midfielders, top3Goalkeepers } = useTop3Players();

  useEffect(() => {
    const numberOfTeamOfMyCountry = getNumberOfTeamsPlayingInCountry(teams, preferredTeam?.countryCode);
    setIsTheOnlyTeamOfMyCountry(numberOfTeamOfMyCountry && numberOfTeamOfMyCountry < 2);
  }, [preferredTeam?.countryCode, teams]);

  const showClubTopContent = clubCompetition && (top3ByTeam?.length || top3ByUserClub?.length);
  const showPreferredTeamContent = !!(preferredTeam && top3ByUserClub?.length);

  useEffect(() => {
    if (clubCompetition) {
      getCountryCode().then((cc) => {
        setSelectedCountry(cc);
      });
    } else {
      setSelectedCountry(selectedTeam?.countryCode);
    }
  }, [selectedTeam, preferredTeam]);

  useEffect(() => {
    loadTop3RanksByTeamCountryCode(selectedCountry).then((data) => {
      setTop3ByTeam(data);
    });
  }, [selectedCountry]);

  useEffect(() => {
    loadTop3RanksByPlayerCountryCode(selectedCountry).then((data) => {
      setTop3ByPlayer(data);
    });
  }, [selectedCountry]);

  useEffect(() => {
    preferredTeam &&
      loadTop3RanksByTeamId(preferredTeam.id).then((data) => {
        setTop3ByUserClub(data);
      });
  }, [preferredTeam]);

  const top3League = useMemo(
    () => <CarouselCard type='league' items={top3ByTeam} countryCode={selectedCountry} />,
    [selectedCountry, top3ByTeam],
  );

  const contentWhenIsTheOnlyTeamOfMyCountry = useMemo(
    () => <CarouselCard items={top3ByUserClub} team={preferredTeam} countryCode={selectedCountry} type='team' />,
    [preferredTeam, top3ByUserClub, selectedCountry],
  );

  const contentWithoutPreferredTeam = (
    <>
      {top3League}
      <CarouselCard type='country' items={top3ByPlayer} countryCode={selectedCountry} />
    </>
  );
  const contentWhenIsNotTheOnlyTeamOfMyCountry = (
    <>
      <CarouselCard type='team' items={top3ByUserClub} team={preferredTeam} countryCode={selectedCountry} />
      {contentWithoutPreferredTeam}
    </>
  );

  const contentWithPreferredTeam = isTheOnlyTeamOfMyCountry
    ? contentWhenIsTheOnlyTeamOfMyCountry
    : contentWhenIsNotTheOnlyTeamOfMyCountry;

  const clubTopContent = showPreferredTeamContent ? contentWithPreferredTeam : contentWithoutPreferredTeam;
  const top3ContentType = clubCompetition ? 'country' : 'team';
  const top3ContentItems = clubCompetition ? top3ByPlayer : top3ByTeam;
  const teamTopContent = useMemo(
    () => (
      <CarouselCard type={top3ContentType} items={top3ContentItems} countryCode={selectedCountry} team={selectedTeam} />
    ),
    [top3ContentType, top3ContentItems, selectedCountry, selectedTeam],
  );

  const handleBtnClick = () => {
    EventBus.dispatch('openPreferredTeam');
    GoogleTagManagerHelper.event(
      'Click',
      'Fav team selector - Loaded from card',
      'Favourite Team Selection',
      {
        section_reference: 'Favourite Team Selection',
      },
      'fav_team_selector_loaded_from_card',
    );
  };

  return (
    <div className={`pk-container pk-theme--dark ${style.carouselRanksWrapper}`}>
      <div className={`pk-col pk-col--span-12 ${style.carouselRanks}`}>
        <ErrorBoundary>
          {
            <div>
              <PkCarousel
                key={hash([
                  preferredTeam,
                  top3ByTeam,
                  top3ByPlayer,
                  top3ByUserClub,
                  top3Forwards,
                  top3Defenders,
                  top3Midfielders,
                  top3Goalkeepers,
                ])}
                breakpoints={JSON.stringify(breakpoints)}
                boxShadowPadding={true}
              >
                <>
                  <div slot='headline'>{t('custom_rankings')}</div>
                  {!preferredTeam && (
                    <div key='preferredteamcard' className={style.favTeamCard}>
                      <PkCard className='pk-card pk-theme--dark'>
                        <div slot='heading'>
                          <div className={style.favTeamHeading}></div>
                        </div>
                        <div slot='body'>
                          <div className={style.favTeamCta}>
                            <h3 className={style.favTeamCtaTitle}>
                              {t(clubCompetition ? 'pick-your-fav-club' : 'pick-your-fav-team')}
                            </h3>
                            <p className={style.favTeamCtaText}>
                              {t(clubCompetition ? 'favourite-club_text' : 'favourite-team_text')}
                            </p>
                            <div className={style.favTeamCtaButtonWrapper}>
                              <PkButton
                                className={style.pkButton}
                                appearance='outlined'
                                type='button'
                                onClick={handleBtnClick}
                              >
                                {t(clubCompetition ? 'select_club' : 'select_team')}
                              </PkButton>
                            </div>
                          </div>
                        </div>
                      </PkCard>
                    </div>
                  )}
                  {/* TODO enable when available <CarouselCard title={'fast_riser'} items={Array(3).fill('')} link={''} /> */}
                  <>{showClubTopContent ? clubTopContent : teamTopContent}</>
                  <CarouselCard type='FORWARD' items={top3Forwards} />
                  <CarouselCard type='MIDFIELDER' items={top3Midfielders} />
                  <CarouselCard type='DEFENDER' items={top3Defenders} />
                  <CarouselCard type='GOALKEEPER' items={top3Goalkeepers} />
                </>
              </PkCarousel>
            </div>
          }
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default memo(CarouselRanks);
