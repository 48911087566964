import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { Team } from '@uefadigital/panenka';

import type { TeamStateType, TeamsStateType } from '../types';
import { endpoints } from '../utils/api/endpoints';
import { resursiveCall } from '../utils/api/fetch';
import { getCountryCode } from '../utils/cookies';
import { storeTeams } from '../utils/localStorage';
import { orderTeams } from '../utils/misc';

export const loadTeamsAction: AsyncThunk<
  {
    teams: Team[];
    selected: Team;
  },
  void,
  unknown
> = createAsyncThunk('teams/load', async () => {
  let result = await resursiveCall<Team>(endpoints.teams, 500);
  result = orderTeams(result);
  const countryCode = await getCountryCode();
  const selected = result.find((ct) => ct.countryCode === countryCode?.toUpperCase());
  return { teams: result, selected };
});

const initialState: TeamsStateType = {
  isLoading: false,
  list: [],
  selected: null,
  preferred: null,
};

export const teams = createSlice({
  name: 'teams',
  initialState: initialState,
  reducers: {
    setPreferred: (state, { payload: data }: PayloadAction<Team>) => ({
      ...state,
      preferred: data,
    }),
    toggleTeam: (state, { payload: { teamCode, id } }: PayloadAction<TeamStateType>) => {
      // teamCode is not guaranteed to pe unique
      const newState = {
        ...state,
        list: state.list.map((ct) => ({
          ...ct,
          isFilter: ct.teamCode === teamCode && ct.id === id ? !ct.isFilter : ct.isFilter,
        })),
      };
      storeTeams(newState);
      return newState;
    },
    setTeams: (state, { payload }: PayloadAction<TeamStateType[]>) => ({
      ...state,
      list: payload,
    }),
    reset: (state) => {
      const newState = {
        ...state,
        list: state.list.map((ct) => ({
          ...ct,
          isFilter: false,
        })),
      };
      storeTeams(newState);
      return newState;
    },
    setSelectedTeam: (state, { payload: data }: PayloadAction<Team>) => ({
      ...state,
      selected: data,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loadTeamsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadTeamsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.selected = payload.selected;
      state.list = payload.teams.map((ct) => ({
        ...ct,
        isFilter: false,
      }));
    });
  },
});
