import { PkSimpleHeader } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import type { SponsorType } from '../../types';
import { getSponsorFromAPI } from '../../utils/api';
import { getColorLightDark, getCssVariableValue } from '../../utils/css-custom-props';
import { getCompetitionURL, isIV } from '../../utils/misc';

import * as style from './style.module.scss';

export const UpperBar: FC<{ testPurpose?: boolean; showButton?: boolean }> = ({ testPurpose, showButton }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [sponsor, setSponsor] = useState<SponsorType>();
  const [presentedByColor, setPresentedByColor] = useState<string>('');
  const container = useRef<HTMLPkSimpleHeaderElement>(null);

  useEffect(() => {
    let mounted = true;
    const loadAsync = async () => {
      const result = await getSponsorFromAPI('performancezone');
      if (!mounted || !result) return;
      setSponsor({ ...result });
    };

    loadAsync();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const sponsoredHeader = container.current;
    if (sponsoredHeader && sponsor) {
      const sponsorBgColor = `--pk-sponsor--${sponsor?.code ?? 'fedex'}`;
      const sponsorTextColor = `--pk-sponsor-${sponsor?.code ? `text--${sponsor?.code}` : '-fedex-01'}`;
      sponsor.bgColor && sponsoredHeader.style.setProperty(sponsorBgColor, sponsor.bgColor);
      if (sponsor.textColor) {
        sponsoredHeader.style.setProperty(sponsorTextColor, sponsor.textColor ?? '--pk-text-01');
        //delivered-by color
        setPresentedByColor(sponsor.textColor ?? '--pk-text-01');
      }
      const bgColor = getCssVariableValue(sponsorBgColor, sponsoredHeader, '');
      const theme = getColorLightDark(bgColor);
      sponsoredHeader.setAttribute('pk-theme', theme);
      sponsoredHeader.setAttribute('bg-color', sponsorBgColor.replace('--pk-', ''));
      sponsoredHeader.setAttribute('title-color', sponsorTextColor.replace('--pk-', ''));
    }
  }, [sponsor]);

  const goHome = () => {
    navigate('/');
  };

  const backUrl = useMemo(() => {
    if (location && location.pathname !== '/') {
      return;
    } else if (document.referrer.includes('statistics/') || testPurpose) {
      //player page
      return getCompetitionURL();
    } else if (document.referrer) {
      return document.referrer;
    } else {
      return getCompetitionURL();
    }
  }, [location, testPurpose]);

  /*
    if referrer is player page, I come back to getFallBackURL (competition not setted up properly)
    in other cases, if I'm not in the path "/" I come back to "/"
    if I'm on "/" , I come back to getRelevantCompetitionURL
    we have to properly valorize relevantcompetition
    */
  const goBack = () => {
    if (backUrl) {
      window.location.href = backUrl;
      return;
    }
    goHome();
  };

  return (
    <PkSimpleHeader
      ref={container}
      className={clsx(style.upperBar, { ['modal-container']: isIV() || showButton })}
      text={t('fedex_performance_zone')}
      bgColor={`sponsor--${sponsor?.code}`}
      titleColor={`sponsor-text--${sponsor?.code}`}
      disableClose={!isIV() && !showButton}
      onPkClose={goBack}
      pk-theme='light'
    >
      {sponsor && (
        <div slot='sponsor' className={clsx(style.upperBarSponsor)}>
          {!!sponsor?.tag && (
            <p className={clsx(style.upperBarLabel)} style={{ color: presentedByColor }}>
              {sponsor.tag}
            </p>
          )}
          <div className={clsx(style.upperBarLogo)}>
            <a href={sponsor.url}>
              <img className={clsx(style.upperBarImg)} loading='lazy' alt='FedEx' src={sponsor?.retinaSrc} />
            </a>
          </div>
        </div>
      )}
    </PkSimpleHeader>
  );
};

export default memo(UpperBar);
