import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { League } from '@uefadigital/panenka';

import { createApi } from '../../store/createApi';
import { getCountryCode } from '../cookies';
import { env } from '../enviroments';

import { endpoints } from './endpoints';
import { headers } from './fetch';

export const domesticApi = createApi({
  reducerPath: 'domesticApi',
  baseQuery: fetchBaseQuery({
    baseUrl: env.domestic,
    headers: headers,
  }),
  endpoints: (builder) => ({
    getLeague: builder.query<League, { countryCode: string }>({
      async queryFn(args) {
        const cc = args.countryCode ?? (await getCountryCode());
        let url = endpoints.league;
        url += `&countryCode=${cc}`;
        const response =
          (await fetch(url, { headers }).then(
            (res) => res.json(),
            () => null,
          )) ?? [];
        const data = response?.[0];
        return { data };
      },
    }),
  }),
});
