import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { createApi } from '../../store/createApi';
import type { RankingType } from '../../types';
import { env } from '../enviroments';

import { createRankingsParams } from './utils';

export const rankingsApi = createApi({
  reducerPath: 'rankingsApi',
  baseQuery: fetchBaseQuery({ baseUrl: env.performances }),
  endpoints: (builder) => ({
    getRanksByPositions: builder.query<RankingType[], string>({
      query: (position) => ({
        url: '/v1/rankings',
        params: createRankingsParams(null, { fieldPosition: position, limit: 3 }),
      }),
      // transformResponse: (response) => response.data.filter((value) => value.rank.totalPoints > 0) ?? []
    }),
  }),
});
