import { PkDivider } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import { memo, useEffect, useMemo, useState } from 'react';

import { useSelector } from '../../store';
import {
  loadTop3RanksByPlayerCountryCode,
  loadTop3RanksByTeamCountryCode,
  loadTop3RanksByTeamId,
} from '../../utils/api';
import { getCountryCode } from '../../utils/cookies';
import { getOptions } from '../../utils/optionsManager';
import { getNumberOfTeamsPlayingInCountry } from '../../utils/utils';

import Top3Content from './Top3Content';

import * as style from './style.module.scss';

const { clubCompetition } = getOptions();

export const Top3 = () => {
  const selectedTeam = useSelector((state) => state.teams.selected);
  const preferredTeam = useSelector((state) => state.teams.preferred);
  const teams = useSelector((state) => state.teams.list);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [top3ByTeam, setTop3ByTeam] = useState(null);
  const [top3ByPlayer, setTop3ByPlayer] = useState(null);
  const [top3ByUserClub, setTop3ByUserClub] = useState(null);
  const [isTheOnlyTeamOfMyCountry, setIsTheOnlyTeamOfMyCountry] = useState(false);

  useEffect(() => {
    const numberOfTeamOfMyCountry = getNumberOfTeamsPlayingInCountry(teams, preferredTeam?.countryCode);
    setIsTheOnlyTeamOfMyCountry(numberOfTeamOfMyCountry && numberOfTeamOfMyCountry < 2);
  }, [preferredTeam?.countryCode, teams]);

  useEffect(() => {
    let mounted = true;
    if (clubCompetition) {
      if (preferredTeam?.countryCode) {
        setSelectedCountry(preferredTeam.countryCode);
      } else {
        getCountryCode().then((cc) => {
          mounted && setSelectedCountry(cc);
        });
      }
    } else {
      setSelectedCountry(selectedTeam?.countryCode);
    }

    return () => {
      mounted = false;
    };
  }, [selectedTeam, preferredTeam]);

  useEffect(() => {
    let mounted = true;
    loadTop3RanksByTeamCountryCode(selectedCountry).then((data) => {
      mounted && setTop3ByTeam(data);
    });

    return () => {
      mounted = false;
    };
  }, [selectedCountry]);

  useEffect(() => {
    let mounted = true;
    loadTop3RanksByPlayerCountryCode(selectedCountry).then((data) => {
      mounted && setTop3ByPlayer(data);
    });
    return () => {
      mounted = false;
    };
  }, [selectedCountry]);

  useEffect(() => {
    let mounted = true;
    preferredTeam &&
      loadTop3RanksByTeamId(preferredTeam.id).then((data) => {
        mounted && setTop3ByUserClub(data);
      });
    return () => {
      mounted = false;
    };
  }, [preferredTeam]);

  const divider = useMemo(
    () => (
      <div className={clsx(style.fpzTop3Separator)}>
        <PkDivider className={clsx('divider--vertical')} direction='vertical'></PkDivider>
      </div>
    ),
    [],
  );

  const top3League = useMemo(
    () => (
      <div className={clsx(style.fpzTop3League, style.fpzTop3Col)}>
        <Top3Content type='league' items={top3ByTeam} countryCode={selectedCountry} useMini={true} />
      </div>
    ),
    [selectedCountry, top3ByTeam],
  );

  const showClubTopContent = clubCompetition && (top3ByTeam?.length || top3ByUserClub?.length);
  const showPreferredTeamContent = !!(preferredTeam && top3ByUserClub?.length);
  const top3ContentType = clubCompetition ? 'country' : 'team';
  const top3ContentItems = clubCompetition ? top3ByPlayer : top3ByTeam;

  const contentWhenIsTheOnlyTeamOfMyCountry = useMemo(
    () => (
      <>
        <div className={clsx(style.fpzTop3League, style.fpzTop3Col, style.fpzTop3Single)}>
          <Top3Content
            type='team'
            items={top3ByUserClub}
            team={preferredTeam}
            countryCode={selectedCountry}
            useMini={false}
          />
        </div>
      </>
    ),
    [top3ByUserClub, preferredTeam, selectedCountry],
  );

  const contentWhenIsNotTheOnlyTeamOfMyCountry = useMemo(
    () => (
      <>
        <div className={clsx(style.fpzTop3League, style.fpzTop3Col)}>
          <Top3Content
            type='team'
            items={top3ByUserClub}
            team={preferredTeam}
            countryCode={selectedCountry}
            useMini={true}
          />
        </div>

        {divider}
        {top3League}
      </>
    ),
    [top3ByUserClub, preferredTeam, selectedCountry, divider, top3League],
  );

  const contentWithPreferredTeam = isTheOnlyTeamOfMyCountry
    ? contentWhenIsTheOnlyTeamOfMyCountry
    : contentWhenIsNotTheOnlyTeamOfMyCountry;

  const contentWithoutPreferredTeam = useMemo(
    () => (
      <>
        {top3League}
        {divider}
        <div className={clsx(style.fpzTop3Country, style.fpzTop3Col)}>
          <Top3Content type='country' items={top3ByPlayer} useMini={true} countryCode={selectedCountry} />
        </div>
      </>
    ),
    [top3ByPlayer, selectedCountry, divider, top3League],
  );

  const teamTopContent = useMemo(
    () => (
      <div className={clsx(style.fpzTop3Teams, style.fpzTop3Col, style.fpzTop3Single)}>
        <Top3Content
          type={top3ContentType}
          items={top3ContentItems}
          countryCode={selectedCountry}
          team={selectedTeam}
          useMini={false}
        />
      </div>
    ),
    [top3ContentType, top3ContentItems, selectedCountry, selectedTeam],
  );

  const clubTopContent = showPreferredTeamContent ? contentWithPreferredTeam : contentWithoutPreferredTeam;

  if (!top3ByTeam?.length && !top3ByPlayer?.length) return <></>;

  return (
    <div
      className={clsx(
        style.fpzTop3,
        'pk-container pk-theme--dark',
        { [style[`${selectedCountry?.toLowerCase()}`]]: !clubCompetition },
        { [style.fpzTeamBg]: !clubCompetition },
      )}
    >
      <div className='pk-col pk-col--span-12 pk-d--flex pk-flex--column pk-flex-md--row'>
        {showClubTopContent ? clubTopContent : teamTopContent}
      </div>
    </div>
  );
};

export default memo(Top3);
