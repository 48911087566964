import { useCallback } from 'react';

import { actions } from '../models';
import { useDispatch, useSelector } from '../store';

export const useRouteState = () => {
  const dispatch = useDispatch();
  const teamId = useSelector((state) => state.route.teamId);
  const playerCountryCode = useSelector((state) => state.route.playerCountryCode);
  const position = useSelector((state) => state.route.position);
  const teamCountryCode = useSelector((state) => state.route.teamCountryCode);
  const setTeamId = useCallback((idTeam) => dispatch(actions.route.setTeamId(idTeam)), [dispatch]);
  const setPlayerCountryCode = useCallback((code) => dispatch(actions.route.setPlayerCountryCode(code)), [dispatch]);
  const setTeamCountryCode = useCallback((code) => dispatch(actions.route.setTeamCountryCode(code)), [dispatch]);
  const setPosition = useCallback((code) => dispatch(actions.route.setPosition(code)), [dispatch]);

  return {
    teamId,
    setTeamId,
    playerCountryCode,
    setPlayerCountryCode,
    teamCountryCode,
    setTeamCountryCode,
    position,
    setPosition,
  };
};
