import Cookies from 'js-cookie';

import { cachedFetch } from './api/fetch';

export const getCountry = async () => {
  const cookieName = 'geo.Country';
  let country = Cookies.get(cookieName);
  if (country) {
    country = JSON.parse(country);
    if (country?.fifaCountryCode) return country;
  }
  country = await cachedFetch('https://www.uefa.com/api/v1/countries');
  if (!country) return {};

  Cookies.set(cookieName, JSON.stringify(country), {
    path: '/',
    domain: window.location.hostname === 'localhost' ? 'localhost' : '.uefa.com',
  });

  return country;
};

export const getCountryCode = async () => (await getCountry())?.fifaCountryCode;
