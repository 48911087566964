import { PkButton } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useReset } from '../../hooks/useReset';
import { actions } from '../../models';
import { loadRanksAction } from '../../models/rankings';
import { useSelector, useDispatch } from '../../store';
import GoogleTagManagerHelper from '../../utils/googleTagManager';
import { getActiveTeams, getActivePositions, anyFilter } from '../../utils/misc';
import FilterLabel from '../FilterLabel';

import * as style from './style.module.scss';

export const ActiveFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { resetPositions, resetTeams, resetRoute } = useReset();
  const hide = useCallback(() => dispatch(actions.filter.hideFilter()), [dispatch]);
  const resetOffset = useCallback(() => dispatch(actions.rankings.resetOffset()), [dispatch]);

  const teamsState = useSelector((state) => state.teams);
  const teams = getActiveTeams(teamsState);
  const positionsState = useSelector((state) => state.filter);
  const positions = getActivePositions(positionsState);
  const showClear = anyFilter(positionsState, teamsState);

  const onClear = () => {
    GoogleTagManagerHelper.event(
      'Click',
      'Clear all filters',
      'Performance Zone',
      { section_reference: 'fpz', interaction: 'Clear all filters' },
      'performance_zone',
    );
    resetPositions?.();
    resetRoute?.();
    resetTeams?.();
    hide?.();
    dispatch(resetOffset());
    dispatch(loadRanksAction());
  };

  return (
    <div className={clsx(style.activeFilters, 'pk-col', 'pk-col--span-12', 'pk-theme-dark')}>
      <div className={clsx(style.activeFiltersWrapper)}>
        {showClear && (
          <PkButton className={clsx(style.button, style.clearAll)} appearance='text' onClick={onClear}>
            {t('clear_all')}
          </PkButton>
        )}
        {teams.length > 0 &&
          teams.map((ct) => (
            <div
              key={`${ct.id}-${ct.teamCode}`}
              data-testid='filter-label-wrapper--team'
              className={style.filterLabelWrapper}
            >
              <FilterLabel type='team' item={ct} />
            </div>
          ))}
        {positions.length > 0 &&
          positions.map((pos) => (
            <div key={pos.id} data-testid='filter-label-wrapper--position' className={style.filterLabelWrapper}>
              <FilterLabel type='position' item={pos} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ActiveFilters;
