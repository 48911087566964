import { PkIdentifier, PkAvatar } from '@uefadigital/panenka-react';
import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PositionType } from '../../types';

import * as style from './style.module.scss';

const PositionBox = ({
  position,
  onItemClick,
}: {
  position: PositionType;
  onItemClick?: (t: PositionType, selected: boolean) => void;
}) => {
  const { t } = useTranslation();

  const handleClick = (
    event: CustomEvent<{
      selected: boolean;
    }>,
  ) => {
    onItemClick && onItemClick(position, event.detail.selected);
  };

  return (
    <PkIdentifier
      data-testid='identifier'
      className={clsx(style.pkIdentifier)}
      enableSelection={true}
      selected={position.active}
      type='vertical'
      primarySize='14'
      onPkChange={handleClick}
    >
      <span slot='prefix'>
        <PkAvatar pk-theme='dark' fallbackImage='' size={48} type='text'>
          <div slot='text'>{t(`${position.id}_code`)}</div>
        </PkAvatar>
      </span>
      <span slot='primary'>{t(position.id)}</span>
    </PkIdentifier>
  );
};

export default memo(PositionBox);
