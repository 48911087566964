import { createSlice } from '@reduxjs/toolkit';

import { positions } from '../resources/positions';
import type { FilterStateType } from '../types';

const initialState: FilterStateType = {
  positions: positions,
  isVisible: false,
};

export const filter = createSlice({
  name: 'filter',
  initialState: initialState,
  reducers: {
    showFilter: (state) => {
      state.isVisible = true;
    },
    hideFilter: (state) => {
      state.isVisible = false;
    },
    togglePosition: (state, { payload }) => {
      const newState = {
        ...state,
        positions: state.positions.map((pos) => ({
          ...pos,
          active: pos.id === payload ? !pos.active : pos.active,
        })),
      };
      return newState;
    },
    setPositions: (state, { payload }) => {
      state.positions = payload;
    },
    resetFilters: (state) => {
      const newState = {
        ...state,
        positions: state.positions.map((pos) => ({
          ...pos,
          active: false,
        })),
      };
      return newState;
    },
  },
});
